<template>
  <Menu
    as="div"
    class="relative"
  >
    <MenuButton
      as="div"
      class="appearance-none hover:text-primary-4"
    >
      <slot name="button">
        <BaseButton
          small
          outlined
          drop-down
        >
          <template #icon>
            <BaseIcon>
              <IconDropDown />
            </BaseIcon>
          </template>
          <template #default>
            {{ buttonText }}
          </template>
        </BaseButton>
      </slot>
    </MenuButton>

    <PopOverTransition>
      <MenuItems
        as="ul"
        class="absolute bottom-11 z-20 text-base text-white whitespace-nowrap rounded-md shadow-lg cursor-pointer outline-none _menu-items bg-neutral-2 md:bottom-auto md:top-11"
        :class="position === 'left' ? 'right-0' : 'left-0'"
      >
        <slot />
      </MenuItems>
    </PopOverTransition>
  </Menu>
</template>
<script>
import {ref} from "vue";
import { useI18n } from "vue-i18n";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import PopOverTransition from '../Transitions/PopOverTransition';
import IconDropDown from "../../icons/IconDropDown";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    PopOverTransition,
    IconDropDown
  },
  props: {
    text: {
      type: String,
      default: "",
      required: false,
    },
    position: {
      type: String,
      default: "left",
    }
  },
  setup(props) {
    const { t } = useI18n();
    const buttonText = ref(props.text);
    if (!props.text) {
      buttonText.value = t('base.actions');
    }
    return { buttonText };
  }
}
</script>

<style>
@layer utilities {
  ._menu-items:focus {
    outline: none;
  }
}
</style>
