import Cookies from "js-cookie";
import { useI18n } from "vue-i18n";
import {computed} from "vue";

export default function useMultiLanguages() {
  const i18n = useI18n();
  const { t } = useI18n();

  const supportedLanguages = [
    {id: "ar", labelShort: "AR", label: "العربية"},
    {id: "cs", labelShort: "CS", label: "Čeština"},
    {id: "de", labelShort: "DE", label: "Deutsch"},
    {id: "en", labelShort: "EN", label: "English"},
    {id: "es", labelShort: "ES", label: "Español"},
    {id: "fr", labelShort: "FR", label: "Français"},
    {id: "hu", labelShort: "HU", label: "Magyar"},
    {id: "it", labelShort: "IT", label: "Italiano"},
    {id: "nl", labelShort: "NL", label: "Nederlands"},
    {id: "pl", labelShort: "PL", label: "Polski"},
    {id: "pt", labelShort: "PT", label: "Português"},
    {id: "sk", labelShort: "SK", label: "Slovenčina"},
    {id: "tr", labelShort: "TR", label: "Türkçe"},
  ];

  const activeLanguage = computed(() => ({
      id: i18n.locale.value,
      label: supportedLanguages.find(lang => lang.id === i18n.locale.value).label,
  }));

  function initialLoadLanguage() {
    const lang = Cookies.get('language_pref');

    if (lang) {
      setLanguage(lang, false);
    } else {
      /**
       * Set browser default language
       */
      let userLang = navigator.language || navigator.userLanguage;
      const langSplitted = userLang.split('-');
      const langId = langSplitted && langSplitted[0];
      if (langId) {
        setLanguage(langId, false);
      }
    }
  }

  function setLanguage(idRaw, store = true) {
    let id = idRaw.toLowerCase();

    if (!supportedLanguages.find(el => el.id === id)){
      id = 'en';
    }

    if (store) {
      Cookies.set('language_pref', id, { expires: 1000000 });
    }

    i18n.locale.value = id;

    const rtlLanguages = ['ar'];
    document.querySelector('html').setAttribute(
        'custom-dir',
        rtlLanguages.includes(id) ? 'rtl' : 'ltr'
    );
  }


  return {
    supportedLanguages,
    activeLanguage,
    setLanguage,
    initialLoadLanguage,
  }
}
