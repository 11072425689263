export default {
  ...require("./validation.json"),
  ...require("./request.json"),
  ...require("./document.json"),
  ...require("./base.json"),
  ...require("./notification.json"),
  ...require("./signature.json"),
  ...require("./urls.json"),
  ...require("./qes.json"),
};
