<template>
  <circle
    cx="16"
    cy="16"
    r="16"
  />
  <path
    d="M15.5 30C22.9558 30 29 23.9558 29 16.5C29 9.04416 22.9558 3 15.5 3C8.04416 3 2 9.04416 2 16.5C2 23.9558 8.04416 30 15.5 30Z"
  />
  <path
    d="M13.7997 20.0099L22.3047 11.5049C22.8447 10.9649 23.7897 11.0999 24.1947 11.6399C24.5997 12.1799 24.5997 12.8549 24.1947 13.3949L14.7447 22.8449C14.2047 23.3849 13.3947 23.3849 12.8547 22.8449L8.8047 18.7949C8.2647 18.2549 8.3997 17.4449 8.9397 16.9049C9.4797 16.4999 10.1547 16.4999 10.6947 16.9049L13.7997 20.0099Z"
    fill="#FFF"
  />
</template>
