<template>
  <path
    style="fill: #fce588"
    d="M8,0.7c4.1,0,7.3,3.3,7.3,7.3s-3.3,7.3-7.3,7.3S0.7,12.1,0.7,8S3.9,0.7,8,0.7z"
  />
  <path
    style="fill: #cb6e17"
    d="M8,12.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C9.1,11.9,8.6,12.4,8,12.4z M8.7,8.1
	C8.6,9,7.4,9,7.3,8.1L6.9,4.4c0-0.4,0.3-0.8,0.7-0.8c0,0,0.1,0,0.1,0h0.7c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0.1,0,0.1L8.7,8.1z"
  />
</template>

<script>
export default {
  name: "ImportantIcon",
};
</script>
