import apiClient from "../api";


export default {
  async submitTestAccount(email, requestId){
    return apiClient.post(`/followup`, {
      email,
      request_id: requestId
    });
  }

}
