<template>
  <div
    :id="fieldHtmlId"
    class="absolute h-16 border rounded-sm cursor-pointer md:border-2 tooltip-container w-30"
    style="transition: 300ms ease-in-out transform"
    :class="{
      'bg-green-10 border-green-8 text-green-1 _field-mine-done':
        fieldStadium.mine.done && fieldStadium.meta.showInputBackground,
      'bg-yellow-10 border-yellow-8 text-yellow-4 hover:border-yellow-4 focus-within:border-yellow-4 _field-mine-todo-required':
        fieldStadium.mine.todoRequired &&
        !fieldStadium.mine.done &&
        !fieldStadium.readOnly &&
        !fieldStadium.isForeign &&
        fieldStadium.meta.showInputBackground,
      'bg-white border-neutral-2 hover:border-primary-4 focus-within:border-primary-4 _field-mine-todo-optional':
        fieldStadium.mine.todoOptional &&
        !fieldStadium.mine.done &&
        !fieldStadium.readOnly &&
        !fieldStadium.isForeign,
      'bg-yellow-10 border-yellow-8 text-yellow-4 hover:border-yellow-4 focus-within:border-yellow-4 _field-mine-highlighted-next':
        fieldStadium.meta.highlightedNext && !fieldStadium.isForeign,
      'bg-neutral-10 border-neutral-9 text-neutral-4 _field-foreign-or-readonly': fieldStadium.isForeign || fieldStadium.readOnly,
      'bg-neutral-10 border-neutral-9 text-neutral-4 _field-date': field.type === 'date-field',
      'bg-transparent border-transparent _field-no-background': !fieldStadium.meta.showInputBackground,
      todo: fieldStadium.meta.highlightedPrevious,
      'rounded-md': field.type === 'signature-field',
      'popup-next-hint-small': field.type !== 'checkbox-field' && highlightedNextPopup,
      'popup-next-hint-large': field.type === 'checkbox-field' && highlightedNextPopup,
      'shadow-checkbox-field-yellow': field.type === 'checkbox-field' && fieldStadium.mine.todoRequired,
      'shadow-checkbox-field-neutral': field.type === 'checkbox-field' && fieldStadium.mine.todoOptional,
      'shadow-checkbox-field-green': field.type === 'checkbox-field' && fieldStadium.mine.done,
    }"
    :style="[
      fieldDimensionsHtml,
    ]"
    @touchstart="onTouchStart"
    @touchend="onTouchEnd"

    @contextmenu="
      e => {
        e.preventDefault();
        toggleDevTool(true);
      }
    "
  >
    <SignatureField
      v-if="field.type === 'signature-field'"
      :field="field"
      :field-dimensions="fieldDimensions"
      :field-stadium="fieldStadium"
    />

    <TextField
      v-if="field.type === 'text-field' && !renderTextArea"
      :field="field"
      :field-stadium="fieldStadium"
      :field-style="fieldStyleObject"
      @onFocus="onFocus"
    />

    <TextAreaField
      v-if="field.type === 'text-field' && renderTextArea"
      :field="field"
      :field-stadium="fieldStadium"
      :field-style="fieldStyleObject"
      @onFocus="onFocus"
    />

    <DateField
      v-if="field.type === 'date-field'"
      :field="field"
      :field-stadium="fieldStadium"
      :field-style="fieldStyleObject"
    />

    <CheckboxField
      v-if="field.type === 'checkbox-field'"
      :field="field"
      :field-stadium="fieldStadium"
      :field-style="fieldStyleObject"
    />
  </div>
</template>

<script>
import {
  ref,
  onBeforeUnmount,
  computed,
} from 'vue';
import { useStore } from "vuex";
import useFieldStadium from "../../composables/Document/useFieldStadium";
import eventBus from "../../eventbus";
import SignatureField from "./FieldTypes/SignatureField";
import TextField from "./FieldTypes/TextField";
import TextAreaField from "./FieldTypes/TextAreaField";
import CheckboxField from "./FieldTypes/CheckboxField";
import DateField from "./FieldTypes/DateField";
import useFieldLayout, { htmlFieldId } from "../../composables/Document/useFieldLayout";
import useBreakPoints from '../../composables/LayoutAndStyle/useBreakPoints';

export default {
  components: {
    SignatureField,
    TextField,
    TextAreaField,
    CheckboxField,
    DateField,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    /**
    * Field Layout
    */
    const { isSmallScreen } = useBreakPoints();

    const {
      fieldDimensions,
      fieldDimensionsHtml,
      fieldMetaStyleHtml,
      mountMe,
      unmountMe,
    } = useFieldLayout(props.field, props.page, isSmallScreen);
    /* mountMe() needs to be called before mounted hook, else it leads to bugs */
    mountMe(); //onMounted(() => mountMe());
    onBeforeUnmount(() => unmountMe());

    const store = useStore();
    const fieldHtmlId = htmlFieldId(props.field);
    const showDev = ref(false);
    const fieldStadium = useFieldStadium(props.field, store);
    const highlightedNextPopup = ref(false);

    /**
     * If this field gets focused but according to our logic this field is not the next in the row,
     * we hide the arrow hint as we assume that the user knows what to to.
     */
    const onFocus = () => {
      const currentTooltipField =
        store.getters["documentEditor/currentTooltipField"];

      const previousTooltipField =
        store.getters["documentEditor/previousTooltipField"];

      if (
        currentTooltipField &&
        currentTooltipField.id !== props.field.id &&
        previousTooltipField.id !== props.field.id
      ) {
        eventBus.emit("pauseNextFieldMode");
      }
    };

    /**
     * Scroll is started from ArrowHint.vue even if the arrow hint itself is hidden (e.g. on a small screen)
     */

    eventBus.on("highlightNextField", () => {
      if (fieldStadium.value.meta.highlightedNext) {
        let timeBomb;

        const timeBombStart = () => setTimeout( () => {
          highlightedNextPopup.value = true;
          window.removeEventListener( 'scroll', scrollListener);

          setTimeout(() => {
            highlightedNextPopup.value = false
          }, 600)
        }, 300);

        const scrollListener = () => {
          clearTimeout( timeBomb );
          timeBomb = timeBombStart();
        };

        window.addEventListener( 'scroll', scrollListener, { passive: true } );
        timeBomb = timeBombStart();
      }
    });


    const onTouchStart = (e) => {
      store.commit('documentEditor/setLastFieldIdTouched', props.field.id);
      e.preventDefault();
    };

    const onTouchEnd = (e) => {
      if (store.state.documentEditor.lastFieldIdTouched === props.field.id) {
        eventBus.emit('onTouchClicked', props.field.id);
      }
      e.preventDefault();
    };

    const fieldStyleObject = computed(() => {
      return {
        height: fieldDimensionsHtml.value.height,
        ...fieldMetaStyleHtml.value,
      };
    });

    const toggleDevTool = (visible) => {
      showDev.value = visible;
    };

    const renderTextArea = computed(() => {
      return fieldDimensions.value.fieldHeight.value > 65;
    });

    return {
      fieldDimensions,
      fieldDimensionsHtml,
      fieldStyleObject,
      fieldStadium,
      fieldHtmlId,
      onFocus,
      toggleDevTool,
      showDev,
      onTouchStart,
      onTouchEnd,
      store,
      highlightedNextPopup,
      renderTextArea,
    };
  },
};
</script>

<style scoped>
  .popup-next-hint-small {
    transform: scale(1.1);
  }
  .popup-next-hint-large {
    transform: scale(1.6);
  }
</style>
