export default function useSignatureHelpers() {
  function getBlobFromBlobString(blobString) {
    return blobString.substr(blobString.indexOf(",") + 1);
  }

  function blobString(blob) {
    if (!blob) {
      return "";
    }
    if (blob.includes("data:image/png")) {
      return blob;
    }

    return `data:image/png;base64,${blob}`;
  }

  function createSignatureImageFromBlob(blob) {
    const image = document.createElement("img");
    image.src = blob;
    image.classList.add("max-h-full", "max-w-full", "mx-auto");
    return image;
  }

  function rotateImage(srcBase64, degrees, callback) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();

    image.onload = function () {
      canvas.width = degrees % 180 === 0 ? image.width : image.height;
      canvas.height = degrees % 180 === 0 ? image.height : image.width;

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((degrees * Math.PI) / 180);
      ctx.drawImage(image, image.width / -2, image.height / -2);

      callback(canvas.toDataURL());
    };

    image.src = blobString(srcBase64);
  }

  return {
    getBlobFromBlobString,
    blobString,
    rotateImage,
    createSignatureImageFromBlob,
  };
}
