<template>
  <div>
    <div class="mt-4 mb-2 font-medium text-neutral-2 rtl:text-right">
      <span>{{ $t("signature.mobile.howTo") }}.</span>
    </div>

    <dropzone
      v-if="!tmpBlob"
      :is-loading="isLoading"
      @uploaded="onSignatureUploaded"
    />

    <ReviewUploadedInput
      v-if="tmpBlob"
      :blob="blobString(tmpBlob)"
      @resetSignature="resetSignature"
      @rotateSignature="clockwise => rotateSignature(clockwise)"
    />
  </div>
</template>

<script>
import Dropzone from "../../Common/Dropzone";
import ReviewUploadedInput from "./ReviewUploadedInput";
import useDeviceDetection from "../../../composables/LayoutAndStyle/useDeviceDetection";
import { ref, watch } from "vue";
import useSignatureHelpers from "../../../composables/Document/useSignatureHelpers";
import SignatureService from "../../../services/SignatureService";
import {onMounted} from "@vue/runtime-core";
export default {
  name: "InputUpload",
  components: { ReviewUploadedInput, Dropzone },
  props: {
    defaultSignature: {
      type: Object,
      default: undefined
    },
  },
  emits: ['onValidationChanged'],
  setup(props, { emit }) {
    const { isMobile } = useDeviceDetection();
    const isLoading = ref(false);
    const {
      blobString,
      getBlobFromBlobString,
      createSignatureImageFromBlob,
      rotateImage,
    } = useSignatureHelpers();

    const tmpBlob = ref();
    const tmpImage = ref("");

    onMounted(() => {
      if (props.defaultSignature && props.defaultSignature.type === "upload") {
        tmpBlob.value = props.defaultSignature.blob;
        tmpImage.value = createSignatureImageFromBlob(tmpBlob.value);

        emit("onValidationChanged", true);
      }
    });

    async function onSignatureUploaded(file) {
      isLoading.value = true;
      emit("onValidationChanged", true);
      const data = new FormData();
      data.append("file", file);
      data.append("type", "file");

      const response = await SignatureService.signatureMedia(data);
      console.log("onSignatureUploaded", response);

      tmpBlob.value = getBlobFromBlobString(blobString(response.data.blob));
      tmpImage.value = createSignatureImageFromBlob(tmpBlob.value);
      isLoading.value = false;
    }

    function getSignatureObj(callback) {
      const signatureObj = {
        type: "upload",
        blob: tmpBlob.value,
        image: tmpImage.value,
      };
      callback(signatureObj);
    }

    function resetSignature() {
      tmpBlob.value = "";
      // mobileSignPending.value = false;
    }

    function rotateSignature(clockwise = true) {
      rotateImage(tmpBlob.value, clockwise ? 90 : -90, resultBase64 => {
        tmpBlob.value = resultBase64;
      });
    }

    return {
      isMobile,
      isLoading,
      onSignatureUploaded,
      tmpBlob,
      blobString,
      getSignatureObj,
      resetSignature,
      rotateSignature,
    };
  },
};
</script>

<style scoped></style>
