<template>
  <!-- TODO: refactor -> re-use dropDownMenuButton component -->
  <Menu
    as="div"
    class="relative"
  >
    <MenuButton
      as="div"
      class="appearance-none"
    >
      <BaseButton
        :text-link="compact"
        :outlined="!compact"
        small
        drop-down
      >
        <template #icon>
          <BaseIcon>
            <IconDropDown />
          </BaseIcon>
        </template>
        <div>
          <span
            v-if="compact"
            class="uppercase font-bold"
          >
            {{ useMultiLang.activeLanguage.value.id }}
          </span>
          <span v-else>
            {{ useMultiLang.activeLanguage.value.label }}
          </span>
        </div>
      </BaseButton>
    </MenuButton>

    <PopOverTransition>
      <MenuItems
        as="ul"
        class="absolute bottom-11 mt-2 text-base text-white whitespace-nowrap rounded-md shadow-lg cursor-pointer outline-none _menu-items bg-neutral-2 md:bottom-auto"
        :class="showLeft ? 'left-0' : 'right-0'"
      >
        <DropDownMenuButtonItem
          v-for="lang in useMultiLang.supportedLanguages.filter(l => l.id !== useMultiLang.activeLanguage.value.id)"
          :key="lang.id"
          @onClick="useMultiLang.setLanguage(lang.id)"
        >
          <template #default>
            <span>{{ lang.label }}</span>
          </template>
          <template #icon>
            <LanguageIconSelector :language="lang" />
          </template>
        </DropDownMenuButtonItem>
      </MenuItems>
    </PopOverTransition>
  </Menu>
</template>
<script>
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import PopOverTransition from '../Transitions/PopOverTransition';
import DropDownMenuButtonItem from "../Navigation/DropDownMenuButtonItem";
import useMultiLanguages from "../../../composables/useMultiLanguages";
import LanguageIconSelector from "./LanguageIconSelector";
import IconDropDown from "../../icons/IconDropDown";

export default {
  components: {
    IconDropDown,
    DropDownMenuButtonItem,
    Menu,
    MenuButton,
    MenuItems,
    PopOverTransition,
    LanguageIconSelector,
  },
  props: {
    compact: {
      type: Boolean,
      default: false,
      required: false,
    },
    showLeft: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  setup() {
    const useMultiLang = useMultiLanguages();

    return {
      useMultiLang
    }
  }
}
</script>

<style>
@layer utilities {
  ._menu-items:focus {
    outline: none;
  }
}
</style>
