export default {
  wifiOff: require("./icons/icon-wifi-off.svg"),
  sms: require("./icons/icon-sms.svg"),
  dataSecurity: require("./illustrations/illu-data-security.svg"),
  verificationPhone: require("./illustrations/illu-verification-phone.svg"),
  signatureShield: require("./illustrations/illu-signature-shield.svg"),
  confetti: require("./illustrations/illu-confetti.svg"),
  logoDefault: require("./logos/logo-default.svg"),
  logoSimple: require("./logos/logo-simple.svg"),
};
