<template>
  <div
    v-if="isVisible"
    ref="footer"
    class="flex flex-col flex-shrink-0 justify-center items-center pb-4 w-full text-base text-neutral-3"
  >
    <div class="flex flex-col items-center text-center">
      <div class="mt-4 text-sm text-neutral-7">
        {{ $t('base.copyrightText') }}
      </div>
      <div class="flex mt-2">
        <DropDownMenuButton class="ml-2 md:ml-0.5">
          <!-- Informationen zu signteq -->
          <template #button>
            <div
              class="flex items-center cursor-pointer filter grayscale hover-hover:hover:filter-none"
              @click="showLinksHandler"
            >
              <img
                :src="svgs.logoDefault"
                alt="signteq.io"
                class="h-6"
              >
              <BaseIcon
                size="30"
                class="cursor-pointer"
                :class="showLinks ? 'transform rotate-180' : ''"
              >
                <IconDropDown />
              </BaseIcon>
            </div>
          </template>
        </DropDownMenuButton>
      </div>
    </div>
    <nav
      v-if="showLinks"
      class="flex flex-wrap justify-center py-2 pr-5 mt-1 space-x-5 text-sm text-neutral-4"
    >
      <TextLink
        :url="$t('urls.signteqLP')"
        text="signteq.io"
        class="ml-0 mb-2.5"
      />
      <TextLink
        v-for="link in footerLinks"
        :key="link"
        :url="$t(`urls.${link}`)"
        :text="$t(`base.${link}`)"
        class="ml-0 mb-2.5"
      />
      <TextLink
        :text="$t('base.changeLanguage')"
        class="ml-0 mb-2.5"
        @click.prevent="showLanguageModal = true"
      />
    </nav>
  </div>
  <teleport to="body">
    <LanguageModal
      v-if="showLanguageModal"
      @close="showLanguageModal = false"
      @submit="showLanguageModal = false"
    />
  </teleport>
</template>
<script>
import { ref, computed } from "vue";
import scrollIntoView from "scroll-into-view";
import useTheme from "../../composables/LayoutAndStyle/useTheme";
import svgs from "../../assets/svgs";
import LanguageModal from "../Common/Language/LanguageModal";
import DropDownMenuButton from "../Common/Navigation/DropDownMenuButton";
import IconDropDown from "../icons/IconDropDown";
import TextLink from "../Common/TextLink";

export default {
  components: {
    IconDropDown,
    DropDownMenuButton,
    LanguageModal,
    TextLink,
  },
  setup() {
    const { theme } = useTheme();
    const showLanguageModal = ref(false);
    const footer = ref(null);
    const showLinks = ref(false);
    const isVisible = computed(() => theme.value.showCopyrightFooter);
    const footerLinks = ["impressum", "dataSecurityPolicies", "cookies", "usagePolicies", "contact"];

    function showLinksHandler() {
      showLinks.value = !showLinks.value;
      scrollIntoView(footer.value);
    }

    return {
      isVisible,
      footer,
      showLinks,
      showLinksHandler,
      svgs,
      showLanguageModal,
      footerLinks,
    }
  }
}
</script>
