<template>
  <AlertBox
    v-if="lastAction && lastAction.type === 'signed_document'"
    class="bg-green-9"
    :on-close="clearLastAction"
  >
    <div class="text-base">
      {{ $t('document.feedback.signedOneDocument1') }}&nbsp;<strong>{{ lastAction.document.name }}</strong>&nbsp;{{ $t('document.feedback.signedOneDocument2') }}
    </div>
  </AlertBox>

  <teleport
    v-if="lastAction"
    to="body"
  >
    <AllDoneModal
      v-if="!areDocsToSignLeft && !allDoneModalShown && lastAction.type === 'signed_document' && !modalClosed && !hasAccount"
      @onClose="closeModal"
    />
  </teleport>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Cookies from "js-cookie";
import AllDoneModal from "./AllDoneModal";
import AlertBox from "../Common/AlertBox";

export default {
  name: "DocsLastActionFeedback",
  components: {
    AlertBox,
    AllDoneModal,
  },
  props: {
    areDocsToSignLeft: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();
    const modalClosed = ref(false);

    const allDoneModalShown = Cookies.get('all_done_popup_shown');

    const lastAction = computed(() => {
      return store.getters["documents/getLastAction"];
    });

    const hasAccount = computed( () => {
      return store.getters['request/hasRecipientAccount'];
    });

    function clearLastAction() {
      console.log("on clear last action");
      store.commit("documents/clearLastAction");
    }

    function closeModal() {
      modalClosed.value = true;
    }

    return {
      lastAction,
      clearLastAction,
      closeModal,
      allDoneModalShown,
      modalClosed,
      hasAccount
    };
  },
};
</script>
