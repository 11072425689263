<template>
  <div>
    <SignPadContainer
      ref="containerRef"
      :active-delete="isValid"
      show-delete
      @deleteSignature="deleteSignature"
    >
      <canvas
        ref="canvasRef"
        class="absolute top-0 left-0 z-40"
        :style="{
          width: `${padDimensions.width}px`,
          height: `${padDimensions.height}px`,
        }"
        @deleteSignature="() => deleteSignature()"
      />
    </SignPadContainer>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import useResizeCanvasContent from "../../../composables/Document/useResizeCanvasContent";
import useSignatureHelpers from "../../../composables/Document/useSignatureHelpers";
import TrashIcon from "../../icons/IconTrash";
import SignPadContainer from "./SignPadContainer";

export default {
  name: "InputDrawPad",
  components: { SignPadContainer },
  props: {
    defaultSignature: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  emits: ["onValidationChanged"],
  setup(props, { emit }) {
    const canvasRef = ref(null);
    const containerRef = ref(null);
    const currentSignature = ref(null);
    const isValid = ref(false);

    const { blobString, createSignatureImageFromBlob } = useSignatureHelpers();

    const { clearSignature, setSignature, padDimensions } =
      useResizeCanvasContent(containerRef, canvasRef, onSignatureSet);

    function setValidation(bool) {
      isValid.value = bool;
      emit("onValidationChanged", bool);
    }

    onMounted(async () => {
      await nextTick();

      currentSignature.value =
        props.defaultSignature && props.defaultSignature.type === "draw"
          ? props.defaultSignature.blob
          : null;

      if (currentSignature.value) {
        const blob = blobString(currentSignature.value);
        setSignature(blob);
        setValidation(true);
      }
    });

    function onSignatureSet(blob) {
      currentSignature.value = blob;
      setValidation(true);
    }

    function deleteSignature() {
      clearSignature();
      setValidation(false);
    }

    // called from parent
    function getSignatureObj(callback) {
      const tmpBlob = currentSignature.value;
      const signatureObj = {
        type: "draw",
        blob: tmpBlob,
        text: null,
        typefont: null,
        image: createSignatureImageFromBlob(tmpBlob),
      };

      deleteSignature();
      callback(signatureObj);
    }

    return {
      padDimensions,
      deleteSignature,
      canvasRef,
      containerRef,
      getSignatureObj,
      TrashIcon,
      isValid,
    };
  },
};
</script>
