<template>
  <!-- Status aller Empfänger -->
  <div
    v-click-outside="closeRecipientsInfo"
    class="cursor-pointer text-neutral-8 hover-hover:hover:text-primary-9"
    @click="toggleRecipientsInfo"
  >
    <BaseIcon
      view-box="0 0 16 16"
      :size="small ? '30' : '40'"
    >
      <RecipientsPendingIcon
        v-if="areRecipientsPending"
        :color="badgeColors.neutral.text"
      />

      <RecipientsCompleteIcon
        v-else
        :color="badgeColors.neutral.text"
      />
    </BaseIcon>


    <RecipientsStatusPopUp
      :document="document"
      :show-recipients-info="showRecipientsInfo"
    />
  </div>
</template>

<script>
import { getCurrentInstance, ref } from "vue";
import RecipientsCompleteIcon from "../icons/IconRecipientsComplete";
import RecipientsPendingIcon from "../icons/IconRecipientsPending";
import RecipientsStatusPopUp from "./RecipientsStatusPopUp";
import useTailwindTheme from "../../composables/LayoutAndStyle/useTailwindTheme";

export default {
  name: "AllRecipientsBadge",
  components: {
    RecipientsCompleteIcon,
    RecipientsPendingIcon,
    RecipientsStatusPopUp,
  },
  props: {
    document: {
      type: Object,
      required: false,
      default: () => {}
    },
    areRecipientsPending: {
      type: Boolean,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const internalInstance = getCurrentInstance();
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const showRecipientsInfo = ref(false);
    const { badgeColors } = useTailwindTheme();

    function toggleRecipientsInfo() {
      emitter.emit("close-recipients-infos");
      showRecipientsInfo.value = !showRecipientsInfo.value;
    }

    function closeRecipientsInfo() {
      showRecipientsInfo.value = false;
    }

    return {
      badgeColors,
      showRecipientsInfo,
      toggleRecipientsInfo,
      closeRecipientsInfo,
    };
  },
};
</script>
