import apiClient from "../api";

/**
 * API Service. Calls, that are about the whole Request itself (= multiple documents)
 */
export default {

  initiate(requestId, recipientId, hash) {
    return apiClient.post(
      `/requests/${requestId}/${recipientId}/initiate`,
      hash
    );
  },

  getRequest(id) {
    return apiClient.get("/requests/" + id);
  },

  declineRequest(requestId, currentDocumentId, reason) {
    return apiClient.post(`/requests/${requestId}/decline`, {
      document_id: currentDocumentId,
      reason: reason,
    });
  }
};
