import {ref} from "vue";

export default function useFocusedElement() {

  const activeElement = ref(null);

  function detectBlur() {
    activeElement.value = undefined;
  }

  function detectFocus() {
    activeElement.value = document.activeElement;
  }

  function mountMe() {
    window.addEventListener ? window.addEventListener('focus', detectFocus, true) : window.attachEvent('onfocusout', detectFocus);
    window.addEventListener ? window.addEventListener('blur', detectBlur, true) : window.attachEvent('onblur', detectBlur);
  }

  function unmountMe() {
    window.removeEventListener('focus', detectFocus);
    window.removeEventListener('blur', detectBlur);
  }

  return {
    activeElement,
    unmountMe,
    mountMe,
  }
}
