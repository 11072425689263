<template>
  <Popover
    as="div"
    class="appearance-none"
  >
    <PopoverButton
      v-if="fieldsLeft > 0"
      as="div"
      class="flex items-center text-base appearance-none"
    >
      <BaseIcon
        size="30"
        class="mt-3"
      >
        <ImportantIcon />
      </BaseIcon>
      <div class="flex md:hidden">
        <span class="font-medium text-yellow-5 mt-1 -ml-2">
          {{ fieldsLeft }}
        </span>
      </div>
      <div class="hidden md:flex text-neutral-1">
        {{ t("base.still") }}&nbsp;
        <span class="font-medium text-yellow-5">
          {{ fieldsLeft }}
          {{ t("document.field", fieldsLeft) }}&nbsp;
        </span>
        {{ t("base.open") }}
      </div>
    </PopoverButton>

    <PopoverButton
      v-else
      as="div"
      class="hidden md:flex items-center appearance-none"
    >
      <BaseIcon
        size="36"
        :color="config.colors.green['5']"
      >
        <CheckIcon />
      </BaseIcon>
      <div class="text-neutral-2">
        {{ $t("document.allRequiredFieldsCompleted") }}
      </div>
    </PopoverButton>

    <PopOverTransition>
      <PopoverPanel class="flex md:hidden absolute bottom-10 right-0 z-10 bg-neutral-9 shadow-md rounded-md py-1 px-2 text-sm">
        <div v-if="fieldsLeft > 0">
          {{ t("base.still") }}
          {{ fieldsLeft }}
          {{ t("document.field", fieldsLeft) }}
          {{ t("base.open") }}
        </div>
        <div v-else>
          {{ $t("document.allRequiredFieldsCompleted") }}
        </div>
      </PopoverPanel>
    </PopOverTransition>
  </Popover>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { useStore } from "vuex";
import ImportantIcon from "../icons/IconImportant";
import CheckIcon from "../icons/IconCheck";
import useTailwindTheme from "../../composables/LayoutAndStyle/useTailwindTheme";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import PopOverTransition from '../Common/Transitions/PopOverTransition';

export default {
  components: {
    PopOverTransition,
    ImportantIcon,
    CheckIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
  },
  setup() {
    const store = useStore();
    const { config } = useTailwindTheme();
    const fieldsLeft = computed(() => {
      return store.getters["documentEditor/fieldsToSignLeftCounted"];
    });

    const { t } = useI18n();
    return {
      t,
      fieldsLeft,
      config,
    };
  },
};
</script>
