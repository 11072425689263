import apiClient from "../api";

export default {
  /**
   * Get the KYC config
   * @param requestId
   * @return {Promise<AxiosResponse<T>>}
   */
  getKYCConfig(requestId) {
    return apiClient.get(
      `/requests/${requestId}/kyc`,
    );
  },

  /**
   * Complete the KYC
   * @param requestId
   * @return {Promise<AxiosResponse<T>>}
   */
  completeKYC(requestId) {
    return apiClient.post(
      `/requests/${requestId}/kyc`,
    );
  },
};
