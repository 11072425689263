import apiClient from "../api";
import useSignatureHelpers from "../composables/Document/useSignatureHelpers";

export default {

  /**
   * Get Blob from Backend for a temporary preview of the signature within the signing process.
   * Does not commit the request or sth like this.
   */
  signatureMedia(data){
    return apiClient.post("/signature-media", data);
  },

  /**
   * Used in Signature Dialog --> Mobile Signing
   * Sends an sms to the user's smartphone with a link so that he can enter directly via the smartphone without QR Code.
   * Only available if there is a phone number deposited on backend side. Meaning only available from the security level "advanced"
   * @param mobileSignId
   * @return {Promise<AxiosResponse<T>>}
   */
  sendMobileUrlViaSms(mobileSignId) {
    return apiClient.post(`/mobile-sign/${mobileSignId}/sms`);
  },


  initMobileSign(requestId, recipientId, type, callback) {
    return apiClient
      .post("/mobile-sign", {
        type,
        request_id: requestId,
        recipient_id: recipientId,
      })
      .then(callback);
  },

  /**
   * MOBILE CALL
   * First call after the user gets from the qr code with his mobile phone to the "mobile app"
   * @param mobileSignId
   * @param hash
   * @return {Promise<AxiosResponse<T>>}
   */
  initiateMobile(mobileSignId, hash) {
    return apiClient.post(`/mobile-sign/${mobileSignId}/initiate`, {
      hash: hash,
    });
  },

  /**
   * MOBILE CALL
   * Mobile signature submit, sending back a signature from the mobile device to the signature modal
   * @param mobileSignId
   * @param hash
   * @param mode
   * @param payload
   * @return {Promise<AxiosResponse<T>>}
   */
  submitMobileSignature(mobileSignId, hash, mode, payload) {
    const { blobString } = useSignatureHelpers();

    return apiClient.post(`/mobile-sign/${mobileSignId}/confirm`, {
      hash,
      signature: blobString(payload),
    });
  },

  /**
   * MOBILE CALL
   * Cancel the Mobile signature
   * @param mobileSignId
   * @param hash
   * @return {Promise<AxiosResponse<T>>}
   */
  cancelMobileSignature(mobileSignId, hash) {
    return apiClient.delete(`/mobile-sign/${mobileSignId}`, {
      hash,
    });
  },
};
