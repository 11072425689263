import apiClient from "../api";

/**
 * API Service. Calls, that are about ONE document
 */
export default {
  getDocument(requestId, documentId) {
    return apiClient.get(`/requests/${requestId}/documents/${documentId}`);
  },

  submitDocument(requestId, documentId, payload){
    return apiClient.post(
      `/requests/${requestId}/documents/${documentId}/sign`,
      payload
    );
  }
};
