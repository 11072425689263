<template>
  <modal
    @onCancel="$emit('close')"
  >
    <template #title>
      {{ $t('base.changeLanguage') }}
    </template>
    <template #description>
      <div class="relative text-left">
        <span class="block mb-1">{{ $t('base.languageModalText') }}</span>
        <DropDownLanguage show-left />
      </div>
    </template>
    <template #buttons>
      <BaseButton @click="$emit('submit')">
        {{ $t('base.submit') }}
      </BaseButton>
    </template>
  </modal>
</template>

<script>
import Modal from "../Modal";
import svgs from "../../../assets/svgs";
import BaseButton from "../../Base/BaseButton";
import DropDownLanguage from "./DropDownLanguage";

export default {
  name: "AllDoneModal",
  components: {
    DropDownLanguage,
    BaseButton,
    Modal
  },
  emits: ["close", "submit"],
  setup() {
    return {
      svgs,
    }
  }
};
</script>
