

/**
 * Get the distance from the [body html element] to the [html element with the given @id] to determine
 * the position of the arrow hint
 * */
export function getHtmlElementAbsoluteTopLeft(id) {
  let ele = document.getElementById(id);
  let top = 0;
  let left = 0;
  let right = 0;

  while (ele.tagName !== 'BODY') {
    top += ele.offsetTop;
    left += ele.offsetLeft;
    right += ele.offsetRight;
    ele = ele.offsetParent;
  }

  return {
    top: top,
    left: left,
    right: right,
    height: document.getElementById(id).clientHeight,
  };
}
