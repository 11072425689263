<template>
  <div
    class="h-full"
    :class="fieldStadium.disabled || fieldStadium.readOnly ? 'cursor-not-allowed' : ''"
    @click="openSignatureModal"
  >
    <div
      v-if="!field.signature || field.signature.blob === undefined"
      class="flex flex-col justify-center px-1 md:px-2"
      :style="fieldStyleObject.container"
    >
      <span
        :style="fieldStyleObject.title"
        class="flex items-center font-bold uppercase text-neutral-4"
      >
        {{ $t("base.signature") }}
      </span>
      <span
        v-if="recipient"
        :style="fieldStyleObject.body"
        class="flex items-start font-medium whitespace-nowrap text-neutral-2"
      >
        {{ recipient.name }}
      </span>
    </div>
    <div
      v-else
      class="w-full h-full bg-center bg-no-repeat bg-contain"
      :style="{
        'background-image': `url('${blob}')`,
      }"
    />
  </div>
  <div
    v-if="field.signature_line"
    class="text-neutral-2"
  >
    <hr class="mt-2 mb-1">
    <div class="text-sm">
      {{ recipient.name }}
    </div>
  </div>

  <teleport to="body">
    <SignatureModal
      v-if="showSignatureModal"
      :field="field"
      @onCancel="closeSignatureModal"
      @onSubmit="onFieldSigned"
    />
  </teleport>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import useSignatureHelpers from "../../../composables/Document/useSignatureHelpers";
import eventBus from "../../../eventbus";
import SignatureModal from "../../../components/DocumentEditor/Modals/SignatureModal";
export default {
  name: "SignatureField",
  components: {
    SignatureModal,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldDimensions: {
      type: Object,
      required: true,
    },
    fieldStadium: {
      type: Object,
      required: true,
    },
  },
  emits: ["onFocus"],
  setup(props, { emit }) {
    const { blobString } = useSignatureHelpers();
    const store = useStore();
    const showSignatureModal = ref(false);

    function onFieldSigned(resObj) {
      closeSignatureModal();

      store.dispatch("documentEditor/updateField", {
        fieldId: props.field.id,
        signature: resObj,
      });

      emit("onFocus");
    }


    function openSignatureModal() {
      if (!props.fieldStadium.readOnly && !props.fieldStadium.disabled) {
          showSignatureModal.value = true;
      }
    }

    function closeSignatureModal() {
      showSignatureModal.value = false;
    }

    const recipient = computed(() => {
      return store.getters["documents/findRecipient"](props.field.recipient_id);
    });

    const blob = computed(() => {
      return blobString(props.field.signature.blob);
    });

    const fieldStyleObject = computed(() => {
      const calculatedHeight = props.fieldDimensions.fieldHeight.value;
      const calculatedFontSize = (ratio) => calculatedHeight * ratio;

      /* set font size style & limiter */
      const fontSizeTitle = calculatedFontSize(0.15)
      const maxFontSizeTitle = 32;
      const fontSizeBody = calculatedFontSize(0.25);
      const maxFontSizeBody = 24;

      return {
        container: {
          height: `${calculatedHeight}px`
        },
        title: {
          fontSize: `${fontSizeTitle < maxFontSizeTitle ? fontSizeTitle : maxFontSizeTitle}px`,
          height: `${calculatedHeight / 2}px`,
        },
        body: {
          fontSize: `${fontSizeBody < maxFontSizeBody ? fontSizeBody : maxFontSizeBody}px`,
          height: `${calculatedHeight / 2}px`,
        },
      };
    });


    eventBus.on('onTouchClicked', (id) => {
      if (props.field.id === id) {
        openSignatureModal();
      }
    });

    return {
      showSignatureModal,
      onFieldSigned,
      openSignatureModal,
      closeSignatureModal,
      blob,
      recipient,
      fieldStyleObject,
    };
  },
};
</script>
