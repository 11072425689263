<template>
  <modal @onCancel="$emit('onCancel')">
    <template
      v-if="!isQes || qesInitiated"
      #title
    >
      <p class="rtl:text-right">
        {{ $t("document.submit.oneLastStep") }}
      </p>
    </template>

    <template #description>
      <div v-if="!isQes || qesInitiated">
        <p
          v-if="!theme.customDisclaimer"
          class="text-base text-neutral-1 rtl:text-right"
        >
          {{ $t("document.submit.description1") }}
          <span class="font-medium">{{
            $t("document.submit.description2Highlighted")
          }}</span>
          {{ $t("document.submit.description3") }}
        </p>

        <p
          v-else
          class="text-base text-neutral-1"
        >
          {{ theme.customDisclaimer }}
        </p>
      </div>

      <div
        v-if="isQes"
        class="mt-4"
      >
        <Loader
          v-if="!qesInitiated"
          :show-text="false"
          class="-mt-4 text-neutral-4"
        />

        <div v-else>
          <label
            for="otp"
            class="block mb-1 font-bold text-gray-700"
          >{{
            $t("qes.enterTAN")
          }}</label>
          <CodeInput v-model="otp" />

          <p
            v-if="!qesError"
            class="w-3/5 mt-2 text-xs text-gray-500"
          >
            {{
              $t("qes.tanSent", {
                phonenumber,
              })
            }}
          </p>
          <p
            v-if="qesError && qesError == 'wrong_otp'"
            class="w-2/3 mt-2 text-xs text-red-5"
          >
            {{ qesErrorText }}
          </p>
        </div>
      </div>

      <div
        v-if="errors && errors.length"
        class="p-3 my-4 text-sm text-red-600 bg-red-100 border border-red-300 rounded "
      >
        <p
          v-for="(error, index) in errors"
          :key="index"
        >
          &raquo; {{ error[0] }}
        </p>
      </div>
    </template>

    <template
      v-if="!isQes || qesInitiated"
      #buttons
    >
      <BaseButton
        small
        outlined
        @click="$emit('onCancel')"
      >
        {{ $t("base.cancel") }}
      </BaseButton>

      <BaseButton
        small
        success
        icon="fal fa-signature"
        :loading="loading || qesConfirming"
        :disabled="loading || qesConfirming || (isQes && ! otpInputValid)"
        @click="onSubmit"
      >
        {{ $t("base.confirmSignature") }}
      </BaseButton>
    </template>
  </modal>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import useTheme from "../../../composables/LayoutAndStyle/useTheme";
import useQes from "../../../composables/useQes";
import Modal from "../../Common/Modal";
import CodeInput from "../../../components/Verification/CodeInput";
import Loader from "../../../components/Common/Loader";

export default {
  components: {
    Modal,
    CodeInput,
    Loader,
  },
  props: {
    errors: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  emits: ["onCancel", "onSubmit"],
  setup(_, { emit }) {
    const { theme } = useTheme();
    const store = useStore();
    const otp = ref(null);
    const loading = computed(() => store.state.loading);
    const isQes = !!store.state.request.request.recipient.qes_token;
    const {
      initiate: initiateQes,
      confirm: confirmQes,
      initiated: qesInitiated,
      confirming: qesConfirming,
      error: qesError,
      errorText: qesErrorText,
      phonenumber,
    } = useQes(store.state.request.request.recipient.qes_token);

    const otpInputValid = computed(() => {
      return otp.value && otp.value.length === 6;
    });

    onMounted(() => {
      if (isQes) {
        initiateQes();
      }
    });

    const onSubmit = () => {
      if (isQes) {
        return confirmQes(otp, data => {
            window.parent.postMessage({
                type: 'COMPLETED',
                data,
            }, '*');

            submitSignature();
        });
      }

      submitSignature();
    };

    function submitSignature() {
      store.commit("SET_LOADING", true);
      emit("onSubmit", isQes);
    }

    return {
      theme,
      onSubmit,
      loading,
      isQes,
      otp,
      otpInputValid,
      phonenumber,
      qesInitiated,
      qesError,
      qesErrorText,
      qesConfirming,
    };
  },
};
</script>
