export const documents = {
  namespaced: true,
  state: () => ({
    documents: undefined,
    lastAction: undefined,
    currentDocument: undefined,
    overviewMode: false,
  }),
  mutations: {
    setDocuments(state, documents) {
      state.documents = documents;
    },
    setActiveDocument(state, document) {
      state.currentDocument = document;
    },
    clearActiveDocument(state) {
      state.currentDocument = null;
    },

    setLastAction(state, lastAction) {
      state.lastAction = lastAction;
    },

    clearLastAction(state) {
      state.lastAction = undefined;
      console.log("clearLastAction", state.lastAction);
    },
    /**
     * If the request has only one document and the request is called at the first time, directly jump into
     * the DocumentEditor. If the user decides to go back and close the document, enable overviewMode
     * @param state
     * @param bool
     */
    setOverviewMode(state, bool) {
      state.overviewMode = bool;
    }
  },
  actions: {
    setActiveDocument(store, document) {
      console.log("active document", document);

      store.commit("setActiveDocument", document);
      store.dispatch("documentEditor/setFields", document.fields, {
        root: true,
      });
    },
    setLastAction(store, lastAction) {
      store.commit("setLastAction", lastAction);
    },
  },
  getters: {
    getLastAction(state) {
      return state.lastAction;
    },
    currentRecipientDocumentRelationship(state, getters, rootState) {
      if (!state.currentDocument) return undefined;
      if (!state.currentDocument.recipients) {
        console.error("There are no recipients set for this document");
        return undefined;
      }

      const recipient = state.currentDocument.recipients.find(
        recipient => recipient.id === rootState.request.recipientId
      );

      if (!recipient) {
        console.error("Recipient for this document not found");
        return undefined;
      }

      return recipient.status;
    },
    isReadOnlyMode(state, getters, rootState) {
      return (
        getters.currentRecipientDocumentRelationship === "completed" ||
        getters.currentRecipientDocumentRelationship === "readonly" ||
          rootState.request.request.declination
      );
    },
    findRecipient(state) {
      return recipientId => {
        return state.currentDocument.recipients.find(
          recipient => recipient.id === recipientId
        );
      };
    },
  },
};
