<template>
  <BackgroundAnimation v-if="showBackgroundAnimation" />
  <ErrorMessage
    v-if="error"
    :error="error"
  />
  <div
    v-else
    class="h-full relative z-10"
  >
    <router-view />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import eventBus from "./eventbus";
import BackgroundAnimation from "./components/Common/BackgroundAnimation";
import ErrorMessage from "./components/Common/ErrorMessage";
import useMultiLanguages from "./composables/useMultiLanguages";

export default {
  components: {
    BackgroundAnimation,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const showBackgroundAnimation = ref(true);
    const multiLang = useMultiLanguages();
    multiLang.initialLoadLanguage();

    const error = computed(() => {
      return store.state.error;
    });

    eventBus.on("showBackgroundAnimation", value => {
      showBackgroundAnimation.value = value;
    });

    return {
      t,
      error,
      showBackgroundAnimation,
    };
  },
};
</script>
<style>
  @import "./assets/fonts/Nunito/nunito.css";
  @import "./assets/styles/tailwind.css";
  @import "./assets/styles/custom.css";
  @import "./assets/styles/libraries/toastify.css";
</style>
