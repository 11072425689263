<template>
  <div
    class="relative w-full bg-white max-w-[800px] md:w-[800px] shadow"
    :style="{
      width: displayedWidth + 'px',
      height: displayedHeight + 'px',
      backgroundImage: 'url(' + page.url + ')',
      backgroundSize: 'contain',
    }"
  >
    <slot />
  </div>
</template>

<script>
import { useDisplayedPageSize } from "../../composables/Document/useDisplayedPageSize";
import { onBeforeUnmount, onMounted } from "vue";

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      displayedHeight,
      displayedWidth,
      mountMe,
      unmountMe,
    } = useDisplayedPageSize(props.page.width, props.page.height);
    onMounted(() => mountMe());
    onBeforeUnmount(() => unmountMe());

    return {
      displayedWidth,
      displayedHeight,
    };
  },
};
</script>

<style></style>
