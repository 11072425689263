<template>
  <p
    v-if="mobileViewOpened"
    class="text-base font-medium"
  >
    {{ $t("signature.mobile.waitForConfirmation") }}
  </p>

  <!-- v-if="mobileSignData" -->
  <p
    v-if="!mobileViewOpened && !smsSent"
    class="text-base font-medium text-gray-700"
  >
    <span class="mb-2 text-base text-gray-700">
      {{ $t("signature.mobile.scanQrCodeDescription") }}
    </span>
  </p>

  <p
    v-if="smsSent"
    class="text-base font-medium text-gray-700"
  >
    {{ $t("signature.mobile.smsSentMobile") }}
  </p>

  <Loader
    v-if="mobileViewOpened"
    :show-text="false"
    class="text-neutral-4 mb-3 pt-0.5"
  />

  <!-- v-if="mobileSignData" -->
  <div v-if="!smsSent && !mobileViewOpened">
    <img
      v-if="qrImage"
      class="mx-auto rounded"
      style="width: 200px; height: 200px"
      :src="qrImage ? `data:image/svg+xml;base64, ${qrImage}` : ''"
    >
    <div
      v-else
      class="flex justify-center items-center mx-auto rounded bg-neutral-10"
      style="width: 200px; height: 200px"
    >
      ...
    </div>
  </div>

  <span
    v-if="smsAvailable && !smsSent"
    class="block text-sm font-normal"
  >
    {{ $t("signature.mobile.smsFallbackHint") }}
    <button
      v-if="!smsPending"
      type="button"
      class="underline"
      @click="sendSms"
    >
      {{ $t("signature.mobile.smsFallbackHintAction") }}</button>.
    <span v-if="smsPending">{{ $t("signature.mobile.smsSending") }}</span>
  </span>

  <div class="my-4 mx-auto text-blue-600 la-ball-clip-rotate-pulse">
    <div />
    <div />
  </div>

  <div class="flex justify-center">
    <BaseButton
      secondary
      small
      @click="() => $emit('onCancel')"
    >
      {{ $t("base.cancelProcess") }}
    </BaseButton>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import SignatureService from "../../../../services/SignatureService";
import Loader from "../../../../components/Common/Loader";
import { useStore } from "vuex";

export default {
  name: "QRCodeMobileSignature",
  components: {
    Loader,
  },
  props: {
    qrImage: {
      type: String,
      default: "",
    },
    smsRequestId: {
      type: String,
      default: "",
    },
    mobileViewOpened: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onCancel"],
  setup(props) {
    const store = useStore();
    const smsPending = ref(false);
    const smsSent = ref(false);

    const sendSms = async () => {
      smsPending.value = true;
      await SignatureService.sendMobileUrlViaSms(props.smsRequestId);
      smsPending.value = false;
      smsSent.value = true;
    };

    const smsAvailable = computed(() => {
      return (
        store.state.request.securityLevel &&
        store.state.request.securityLevel !== "basic"
      );
    });

    return {
      sendSms,
      smsPending,
      smsSent,
      smsAvailable,
    };
  },
};
</script>

<style scoped></style>
