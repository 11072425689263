import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import eventBus from "./eventbus";
import ClickOutsideEvent from "./directives/clickOutsideEvent";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  autoTrackSessions: false,
});
const bugsnagVue = Bugsnag.getPlugin('vue');

/* Auto Import all /Base located Components starting with [Base..] */
const requireComponent = require.context(
  "./components/Base",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

const app = createApp(App);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  )
  app.component(componentName, componentConfig.default || componentConfig);
});

app.use(bugsnagVue);
app.use(store);
app.use(router);
app.use(i18n);

app.config.globalProperties.emitter = eventBus;
app.directive("click-outside", ClickOutsideEvent);
app.mount("#app");
