<template>
  <circle
    cx="12"
    cy="12"
    r="10"
    class="st1"
  />
  <path
    class="st0"
    d="M9.4,11H17c0.6,0,1,0.4,1,1s-0.4,1-1,1H9.4l2.3,2.3c0.4,0.4,0.5,1,0.1,1.4s-1,0.5-1.4,0.1c0,0-0.1-0.1-0.1-0.1
	l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4c0.4-0.4,1-0.3,1.4,0.1c0.3,0.4,0.3,0.9,0,1.3L9.4,11L9.4,11z"
  />
</template>

<style scoped>
.st0{fill:#1F2933;}
.st1{fill:#E4E7EB;}
</style>
