import i18n from "../../i18n";

/**
 * Project based "sending/broadcast" Request, not dev context based
 */
export default function useRequest() {
  /**
   *  set up header text depending on type and count
   */
  const getHeaderText = function (request, docsToSign) {
    let action = i18n.global.t("request.toView");
    let quantity = request.documents.length;

    if (docsToSign.length > 0) {
      action = i18n.global.t("request.toSign");
      quantity = docsToSign.length;
    }

    return i18n.global.t("request.headerText", {
      sender: request.sender.name,
      action: action,
      quantity: i18n.global.t("request.documentQuantity", quantity),
    });
  };

  return {
    getHeaderText,
  };
}
