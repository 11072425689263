<template>
  <div class="flex flex-col justify-center w-full h-full">
    <BaseInput
      ref="inputRef"
      v-model="textInput"
      :placeholder="field.placeholder"
      :style="fieldStyle"
      class="h-full outline-none border-none bg-transparent focus:ring-0 focus:outline-none pl-0.5 rtl:pr-0.5"
      :disabled="fieldStadium.disabled || fieldStadium.readOnly"
      :class="[
        fieldStadium.disabled || fieldStadium.readOnly
          ? 'cursor-not-allowed'
          : '',
        fieldStadium.mine.todoOptional ? '' : '',
        fieldStadium.mine.todoRequired ? '' : '',
        fieldStadium.mine.done ? '' : '',
      ]"
      @keyup.enter="resumeNextField"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import eventBus from "../../../eventbus";
import {
  ref,
  onMounted,
  onBeforeUnmount,
  watch
} from "vue";

export default {
  name: "TextField",
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldStadium: {
      type: Object,
      required: true,
    },
    fieldStyle: {
      type: Object,
      required: true,
    },
  },
  emits: ["onFocus"],
  setup(props, { emit }) {
    const store = useStore();
    const textInput = ref("");
    const inputRef = ref(null);

    watch(textInput, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        store.dispatch("documentEditor/updateField", {
          fieldId: props.field.id,
          content: newVal,
        });
        emit("onFocus");
      }
    });

    function resumeNextField() {
      eventBus.emit("resumeNextFieldMode");
    }


    onMounted(() => {
      if (props.field.value) {
        textInput.value = props.field.value;
      }

      eventBus.on("focusCurrentField", () => {
        if (props.fieldStadium.meta.highlightedNext) {
          inputRef.value.focusInput();
        }
      });

      eventBus.on('onTouchClicked', (id) => {
        if (props.field.id === id) {
          inputRef.value.focusInput();
        }
      });
    });

    onBeforeUnmount(() => {
      eventBus.off('focusCurrentField');
      eventBus.off('onTouchClicked');
    });

    return {
      textInput,
      inputRef,
      resumeNextField,
    };
  },
};
</script>
