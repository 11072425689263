import SignatureService from "../services/SignatureService";

/**
 * This store is only in use when a user wants to sign with his/her mobile phone (draw / upload). It's not used in the main app!
 **/
export const mobileSign = {
  namespaced: true,
  state: () => ({
    error: undefined,
    loaded: false,
    mode: undefined,
    hash: undefined,
    mobileSignId: undefined,
  }),
  mutations: {
    SET_ERROR(state, errorCode) {
      state.error = errorCode;
    },
    SET_LOADED(state, bool) {
      state.loaded = bool;
    },
    setMode(state, mode) {
      state.mode = mode;
    },
    setMobileSignId(state, id) {
      state.mobileSignId = id;
    },
    setHash(state, hash) {
      state.hash = hash;
    },
  },
  actions: {
    async authenticate({ commit }, args) {
      const response = await SignatureService.initiateMobile(
        args.mobileSignId,
        args.hash
      );

      if (response.status === 403) {
        commit("SET_ERROR", "already_signed");
        return;
      } else if (response.status === 422) {
        commit("SET_ERROR", "wrong_hash");
        return;
      }

      commit("setMobileSignId", args.mobileSignId);
      commit("setHash", args.hash);

      commit("SET_LOADED", true);
    },
  },
  getters: {
    previousTooltipField(state) {
      return state.previousTooltipField;
    },
  },
};
