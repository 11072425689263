<template>
  <div>
    <SignPadContainer
      :active-delete="true"
      show-rotate
      show-delete
      @deleteSignature="$emit('resetSignature')"
      @rotateSignature="e => $emit('rotateSignature', e)"
    >
      <div
        class="absolute top-0 left-0 right-0"
        style="height: 100%; width: 100%"
      >
        <img
          :src="blob"
          class="max-w-full max-h-full"
          style="max-width: 100%; max-height: 100%"
        >
      </div>
    </SignPadContainer>
  </div>
</template>

<script>
import SignPadContainer from "./SignPadContainer";
export default {
  name: "ReviewUploadedInput",
  components: { SignPadContainer },
  props: {
    blob: {
      type: String,
      default: undefined,
    },
  },
  emits: ["rotateSignature", "resetSignature"],
};
</script>

<style scoped></style>
