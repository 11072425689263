import { ref } from "vue";

/**
 * Calculates one document's displayed screen dimension
 * in relation of the document's aspect ratio.
 *
 * Note:
 * add unmountMe and mountMe to have a dynamic recalculation of the dimensions.
 * If you do not add them, you have to call calculateDisplayHeight/Width on demand to get the dimensions
 */
export function useDisplayedPageSize(pageWidth, pageHeight) {
  const displayedWidth = ref(null);
  const displayedHeight = ref(null);

  const calculateDisplayWidth = () => {
    if (window.innerWidth <= 600) {
      // window width - padding (px-4)
      return window.innerWidth - 32;
    }

    if (window.innerWidth <= 864) {
      /* Starting at 880 due to the margin set around the page */
      /* window width - padding */
      // return Math.min(window.innerWidth - 24, 800);
      return window.innerWidth - 64 - 24;
    }
    return 800;
  };

  /**
   * Keep the aspect ratio
   */
  const calculateDisplayHeight = () => {
    return calculateDisplayWidth() * (pageHeight / pageWidth);
  };

  const onResizeListener = () => {
    displayedWidth.value = calculateDisplayWidth();
    displayedHeight.value = calculateDisplayHeight();
  };

  function mountMe() {
    onResizeListener();
    window.addEventListener("resize", onResizeListener);
  }

  function unmountMe() {
    window.removeEventListener("resize", onResizeListener);
  }

  return {
    displayedHeight,
    displayedWidth,
    calculateDisplayWidth,
    calculateDisplayHeight,
    mountMe,
    unmountMe,
  };
}
