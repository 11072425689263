import RequestService from "../services/RequestService";
import defaultTheme from "../themes/signteq";
import {adjustColor} from "../functions/helpers";

export const request = {
  namespaced: true,
  state: () => ({
    request: undefined,
    requestId: undefined,
    recipientId: undefined,
    securityLevel: undefined,
    localization: undefined,
    signatureSettings: undefined,
    branding: {
      logoDark: defaultTheme.logoDark,
      logoLight: defaultTheme.logoLight,
      primaryBgColor: defaultTheme.primaryBgColor,
      primaryBgColorDark: adjustColor(defaultTheme.primaryBgColor, -20),
      showPrivacyBar: defaultTheme.showPrivacyBar,
      showCopyrightFooter: defaultTheme.showCopyrightFooter,
      showToolbarLinks: defaultTheme.showToolbarLinks,
      customDisclaimer: defaultTheme.customDisclaimer,
    },
  }),
  mutations: {
    setRequestId(state, id) {
      state.requestId = id;
    },
    setRecipientId(state, id) {
      state.recipientId = id;
    },
    setSecurityLevel(state, level) {
      state.securityLevel = level;
    },
    setRequest(state, request) {
      state.request = request;
    },
    setSignatureSettings(state, signatureSettings) {
        state.signatureSettings = signatureSettings;
    },
    setLocalization(state, localization) {
      state.localization = localization;
    },
    setBranding(state, branding) {
      if (!branding){
        return;
      }

      if (branding.logo_online_dark) {
        state.branding.logoDark = branding.logo_online_dark;
      }
      if (branding.logo_online_light) {
        state.branding.logoLight = branding.logo_online_light;
      }
      if (branding.primary_bg_color) {
        state.branding.primaryBgColor = branding.primary_bg_color;
        state.branding.primaryBgColorDark = adjustColor(branding.primary_bg_color, -20);
      }

      ['showPrivacyBar', 'showCopyrightFooter', 'showToolbarLinks', 'customDisclaimer'].forEach(key => {
        if (Object.prototype.hasOwnProperty.call(branding, key)) {
          state.branding[key] = branding[key];
        }
      });
    },
  },
  actions: {
    async fetchRequest({state, commit}) {
      const { data: request } = await RequestService.getRequest(state.requestId);

      console.log("#Request received:", request);

      commit('setRequest', request);
      commit('documents/setDocuments', request.documents, {root: true});

      return request;
    },
    async declineRequest({state, dispatch}, { reason, document }) {
      const res = await RequestService.declineRequest(state.requestId, document.id, reason );

      console.log("Declined request response", res);

      await dispatch(
        "documents/setLastAction",
        {
          type: "declined_document",
          document: {
            id: document.id,
            name: document.name,
          },
        },
        { root: true }
      );
    }
  },
  getters: {
    hasRecipientAccount(state) {
      return state.request && state.request.recipient && state.request.recipient.has_account
    }
  },
};
