import {sortFields} from "../composables/Document/useFieldLayout";
import {isFieldForRecipient, isFieldOpen, isFieldRequired} from "../composables/Document/useFieldStadium";
import DocumentService from "../services/DocumentService";

export const documentEditor = {
  namespaced: true,
  state: () => ({
    tooltipActive: true,
    currentTooltipField: undefined,
    previousTooltipField: undefined,
    documentFields: [],
    fieldsToSignLeft: [],
    lastFieldIdTouched: undefined,
    signatureTemplate: undefined
  }),
  mutations: {
    setFieldsToSign(state, documentFields) {
      state.documentFields = documentFields;
    },
    reset(state) {
      state.documentFields = [];
      state.currentTooltipFieldId = undefined;
      state.tooltipActive = true;
    },

    setFieldsToSignLeft(state, fieldsToSignLeft) {
      state.fieldsToSignLeft = fieldsToSignLeft;
    },

    detectCurrentTooltipField(state) {
      // const fields = _sortBy(state.fieldsToSignLeft, ['page', 'y']);
      const fields = state.fieldsToSignLeft.sort(sortFields);
      const newField = fields && fields.length > 0 && fields[0];

      if (
        !state.previousTooltipField ||
        (newField &&
          state.currentTooltipField &&
          newField.id !== state.currentTooltipField.id)
      ) {
        state.previousTooltipField = state.currentTooltipField;
      }

      state.currentTooltipField = newField;
    },
    setLastFieldIdTouched(state, fieldId) {
      state.lastFieldIdTouched = fieldId;
    },
    setSignatureTemplate(state, signature) {
      state.signatureTemplate = { ...signature };
    }
  },
  actions: {
    setFields(store, documentFields) {
      store.commit("reset");
      store.commit("setFieldsToSign", documentFields);
      store.dispatch("refreshFields");
    },
    refreshFields(store) {
      store.commit(
        "setFieldsToSignLeft",
        store.state.documentFields.filter(el =>
          isFieldOpen(el, store.rootState.request.recipientId) && isFieldRequired(el, store.rootState.request.recipientId)
        )
      );
      store.commit("detectCurrentTooltipField");
    },
    updateField(store, payload) {
      const field = store.state.documentFields.find(
        el => el.id === payload.fieldId
      );
      if (field) {
        if (payload.content !== null && payload.content !== undefined) {
          field.value = payload.content;
        } else if (payload.signature) {
          field.signature = payload.signature;
        }

        store.dispatch("refreshFields");
      } else {
        console.error("Field not found in [documentEditor/updateField]");
      }
    },
    async submitDocument({ getters, dispatch, rootState }, document) {
      const payload = getters["fieldsForRecipient"];

      await DocumentService.submitDocument(rootState.request.requestId, document.id, payload);

      await dispatch(
        "documents/setLastAction",
        {
          type: "signed_document",
          document: {
            id: document.id,
            name: document.name,
          },
        },
        { root: true }
      );
    }
  },
  getters: {
    previousTooltipField(state) {
      return state.previousTooltipField;
    },
    currentTooltipField(state) {
      return state.currentTooltipField;
    },
    fieldsForRecipient(state, getters, rootState) {
      return state.documentFields.filter(el =>
        isFieldForRecipient(el, rootState.request.recipientId)
      );
    },
    fieldsTotalCounted(state) {
      return state.documentFields.length;
    },
    fieldsToSignTotalCounted(state, getters, rootState) {
      return state.documentFields.filter(el =>
        isFieldRequired(el, rootState.request.recipientId)
      ).length;
    },
    fieldsToSignLeft(state) {
      return state.fieldsToSignLeft;
    },
    fieldsToSignLeftCounted(state) {
      return state.fieldsToSignLeft.length;
    },
    isFieldCompleted(state, getters, rootState) {
      return fieldId => {
        const field = state.documentFields.find(el => {
          return el.id.toString() === fieldId.toString();
        });
        return !isFieldOpen(field, rootState.request.recipientId);
      };
    },
    getSignDocumentState(state, getters) {
      const startC = getters.fieldsToSignTotalCounted;
      const currentC = getters.fieldsToSignLeftCounted;
      if (startC === currentC && currentC !== 0) {
        return "start";
      } else if (currentC === 0) {
        return "sign";
      } else {
        return "continue";
      }
    },
  },
  incrementIfOddOnRootSum({ state, commit, rootState }) {
    if ((state.count + rootState.count) % 2 === 1) {
      commit("increment");
    }
  },
};
