import { useI18n } from "vue-i18n";

/**
 * TODO What is this method for?
 * @return {{locale: {string: {min: (function({min?: *}): string)}, mixed: {required: string}}}}
 */
export default function useFormValidation() {
  const { t } = useI18n();

  const locale = {
    mixed: {
      required: t("validation.requiredField"),
    },
    string: {
      min: ({ min }) => t("validation.fieldToShort", { min: min }),
    },
  };

  return {
    locale,
  };
}
