import { ref } from "vue";

/**
 * A promise that takes care of the loading and result state.
 *
 * For usage, call createPromise()
 * 
 * @param fn async method
 * @return { result, loading, createPromise }
 */
export default function usePromise(fn) {
  const result = ref(null);
  const loading = ref(false);
  const createPromise = async (...args) => {
    loading.value = true;
    result.value = null;
    try {
      result.value = await fn(...args);
    } finally {
      loading.value = false;
    }
  };
  return { result, loading, createPromise };
}
