<template>
  <label class="text-xs md:text-sm font-bold tracking-wider uppercase select-none md:relative text-neutral-1">
    {{ label }}
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
      required: true
    },
  }
}
</script>
