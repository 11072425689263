<template>
  <BaseButton
    small
    text-link
    :on-click="() => router.back()"
  >
    <template #icon>
      <BaseIcon>
        <IconBack />
      </BaseIcon>
    </template>
    <template #default>
      {{ $t("base.goBack") }}
    </template>
  </BaseButton>
</template>
<script>
import router from "../../../router";
import IconBack from "../../icons/IconBack";

export default {
  components: {
    IconBack
  },
  setup () {
    return {
      router,
    }
  }
}
</script>
