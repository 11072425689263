import { ref, onMounted, onBeforeUnmount } from "vue";
import SignaturePad from "signature_pad";

/**
 * If the screen changes, this method will adopt the content of the canvas (signature drawn by the user) to it's new size.
 * @param signPadContainerComponent parent. keeps the aspect ratio
 * @param canvas simply imitates the size of the parent. As the content of a canvas does not resize automatically, we resize it manually
 * @param onSignatureSet callback to be called when the signature is set
 */
export default function useResizeCanvasContent(
    signPadContainerComponent,
    canvas,
    onSignatureSet
) {
    const container = ref(undefined);
    const padDimensions = ref({});
    let _pad = null;
    let _signature;
    let _lastDimensions = {};

    onMounted(() => {
        window.addEventListener("resize", resizeCanvas);
        //window.addEventListener("orientationchange", resizeCanvas);

        container.value = signPadContainerComponent
            ? signPadContainerComponent.value.signPadContainerRef
            : undefined;

        updatePadDimensions();

        _pad = new SignaturePad(canvas.value, {
            onEnd: () => {
                _signature = _pad.toDataURL();

                let ratio = Math.max(window.devicePixelRatio || 1, 1);
                _lastDimensions.width = canvas.value.width / ratio;
                _lastDimensions.height = canvas.value.height / ratio;

                //onSignatureSet(getCroppedCanvasImage(canvas.value));
                onSignatureSet(_signature);
            },
        });

        resizeCanvas();
    });

    onBeforeUnmount(() => {
        window.removeEventListener("resize", resizeCanvas);
        window.removeEventListener("orientationchange", resizeCanvas);
    });

    // calculates the maximum size of the signature so that it still
    // fits into the new canvas size and is not cut off horizontally or vertically.
    const getMaxSignatureDimensions = () => {
        let ratio = Math.max(window.devicePixelRatio || 1, 1);
        const newWidth = canvas.value.width / ratio;
        const newHeight = canvas.value.height / ratio;

        // initially we set it to the original size of the signature
        let width = _lastDimensions.width;
        let height = _lastDimensions.height;

        // if the signature height does not fit into the new height, resize it to the new height
        if (newHeight < height) {
            width = width * newHeight / height;
            height = newHeight;
        }

        // if the signature width does not fit into the new width, resize it to the new width
        // do not refactor to 'elseif' (n)or 'else' here!
        if (newWidth < width) {
            height = height * newWidth / width;
            width = newWidth;
        }

        let offset = Math.abs(newHeight - height);

        return {
            height,
            width,
            yOffset: isNaN(offset) ? 0 : offset
        };
    };

    const updatePadDimensions = () => {
        padDimensions.value.width = container.value.offsetWidth
        padDimensions.value.height = container.value.offsetHeight
    };

    const clearSignature = () => {
        _pad.clear();
        _signature = null;
    };

    const getImageDimensions = (blob) => {
      return new Promise ((resolved) => {
        let image = new Image()
        let ratio = Math.max(window.devicePixelRatio || 1, 1);

        image.onload = () => {
          resolved({
              width: image.width / ratio,
              height: image.height / ratio,
            })
        };

        image.src = blob
      });
    }

    const setSignature = async (blob = null) => {
        let signature = blob || _signature;
        if (!signature) {
            return;
        }

        // if we have set the signature from an external source and therfore have
        // not set _lastDimensions in the onEnd() event, we set it here
        if (blob) {
            const imageDimensions = await getImageDimensions(blob);
            _signature = signature;
            _lastDimensions.width = imageDimensions.width;
            _lastDimensions.height = imageDimensions.height;
        }

        const { yOffset, width, height } = getMaxSignatureDimensions();

        // as the libary has a bug with fromDataURL()
        // we manually populate the canvas.
        //_pad.fromDataURL(signature, dimensions);
        let image = new Image();
        _pad.clear();
        image.src = signature;
        image.onload = () => {
            canvas.value.getContext("2d").drawImage(image, 0, yOffset, width, height);
        };
        _pad._isEmpty = false;
    };

    const resizeCanvas = () => {
        updatePadDimensions();

        let ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.value.width = padDimensions.value.width * ratio;
        canvas.value.height = padDimensions.value.height * ratio;
        canvas.value.getContext('2d').scale(ratio, ratio);

        // when the width or height of a canvas is resized, it will be automatically cleared by the browser.
        // SignaturePad doesn't know about it by itself, so we have to call clear() to make sure that the internal
        // structures and data sets from SignaturePad are updated
        _pad.clear();

        // reset the signature to the previously saved one
        setSignature();
    };

    // https://github.com/szimek/signature_pad/issues/49#issuecomment-867566006
    /*const getCroppedCanvasImage = canvas => {
        let originalCtx = canvas.getContext('2d');

        let originalWidth = canvas.width;
        let originalHeight = canvas.height;
        let imageData = originalCtx.getImageData(0, 0, originalWidth, originalHeight);

        let minX = originalWidth + 1, maxX = -1, minY = originalHeight + 1, maxY = -1, x = 0, y = 0, currentPixelColorValueIndex;

        for (y = 0; y < originalHeight; y++) {
            for (x = 0; x < originalWidth; x++) {
                currentPixelColorValueIndex = (y * originalWidth + x) * 4;
                let currentPixelAlphaValue = imageData.data[currentPixelColorValueIndex + 3];
                if (currentPixelAlphaValue > 0) {
                    if (minX > x) minX = x;
                    if (maxX < x) maxX = x;
                    if (minY > y) minY = y;
                    if (maxY < y) maxY = y;
                }
            }
        }

        let croppedWidth = maxX - minX;
        let croppedHeight = maxY - minY;
        if (croppedWidth < 0 || croppedHeight < 0) return null;
        let cuttedImageData = originalCtx.getImageData(minX, minY, croppedWidth, croppedHeight);

        let croppedCanvas = document.createElement('canvas'),
            croppedCtx = croppedCanvas.getContext('2d');

        croppedCanvas.width = croppedWidth;
        croppedCanvas.height = croppedHeight;
        croppedCtx.putImageData(cuttedImageData, 0, 0);

        return croppedCanvas.toDataURL();
    }*/

    return {
        clearSignature,
        setSignature,
        padDimensions
    };
}
