<template>
  <div
    v-for="message in errorMessages"
    :key="message"
    class="flex pt-1 text-sm text-neutral-4"
  >
    <BaseIcon size="28">
      <ImportantIcon />
    </BaseIcon>
    <span class="-ml-1">
      {{ message.toString() }}
    </span>
  </div>
</template>

<script>
import ImportantIcon from "../icons/IconImportant";

export default {
  components: {
    ImportantIcon
  },
  props: {
    errorMessages: {
      type: Array,
      required: false,
      default () {
        return []
      },
    }
  }
}
</script>
