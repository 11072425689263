<template>
  <BaseIcon>
    <IconDE v-if="language.id === 'de'" />
    <IconEN v-else-if="language.id === 'en'" />
  </BaseIcon>
</template>

<script>
import IconDE from "../../icons/language/IconDE";
import IconEN from "../../icons/language/IconEN";
export default {
	name: "LanguageIconSelector",
  components: {IconEN, IconDE},
  props: {
    language: {
      type: Object,
      required: true,
    },
  },
}
</script>
