import apiClient from "../api";

/**
 * QES Service Calls
 */
export default {

	/**
	 * Initiate QES
	 * @param sessionToken
	 * @return {Promise<AxiosResponse<T>>}
	 */
	initiate(sessionToken) {
        // temp for testing
        //return new Promise((resolve) => setTimeout(resolve, 3000));

		return apiClient.post('/qes/initiate', {
			sessionToken
		});
	},

    /**
	 * Complete QES
	 * @param sessionToken
	 * @param otp
	 * @return {Promise<AxiosResponse<T>>}
	 */
	complete(sessionToken, otp, requestId, documentId, fields) {
        // temp for testing
        //return new Promise((resolve) => setTimeout(resolve, 3000));

        return apiClient.post(`/requests/${requestId}/documents/${documentId}/sign-qes`, {
            sessionToken,
            otp,
            fields,
        });
	},
}
