import ar from "./ar/index";
import cs from "./cs/index";
import de from "./de/index";
import en from "./en/index";
import es from "./es/index";
import fr from "./fr/index";
import hu from "./hu/index";
import it from "./it/index";
import nl from "./nl/index";
import pl from "./pl/index";
import pt from "./pt/index";
import sk from "./sk/index";
import tr from "./tr/index";

export default {
  ar,
  cs,
  de,
  en,
  es,
  fr,
  hu,
  it,
  nl,
  pl,
  pt,
  sk,
  tr,
};
