export default {
  name: "signteq",
  logoDark: require("../assets/svgs/logos/logo-simple.svg"),
  logoLight: require("../assets/svgs/logos/logo-simple.svg"),
  primaryBgColor: "#4D3DF7",
  showPrivacyBar: false,
  showCopyrightFooter: true,
  showToolbarLinks: true,
  customDisclaimer: null,
};
