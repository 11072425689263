<template>
  <div class="text-neutral-1">
    <div
      v-if="errorCode === 'already_signed'"
      class="p-4 s-default-page-spacing"
    >
      <HeaderMessage
        message-only
        :text="$t('signature.mobile.alreadySigned')"
      />
    </div>

    <div
      v-else-if="submitted"
      class="p-4 s-default-page-spacing"
    >
      <HeaderMessage
        message-only
        :text="$t('signature.mobile.mobileFinishedTitle')"
        :caption="$t('signature.mobile.mobileFinishedText')"
      />
    </div>

    <div
      v-else-if="canceled"
      class="p-4 s-default-page-spacing"
    >
      <HeaderMessage
        message-only
        :text="$t('signature.mobile.mobileCanceledTitle')"
        :caption="$t('signature.mobile.mobileCanceledText')"
      />
    </div>

    <div
      v-else
      class="h-screen"
    >
      <div class="flex items-center justify-between px-4 py-2 border-b shadow-sm bg-neutral-9 border-neutral-8">
        <BaseButton
          small
          text-link
          :on-click="() => cancel()"
        >
          <template #icon>
            <BaseIcon>
              <IconClose />
            </BaseIcon>
          </template>
          <template #default>
            <span>Abbrechen</span>
          </template>
        </BaseButton>

        <BaseButton
          :pending="isPending"
          :disabled="!isFormValid"
          class="ml-12"
          @click="confirmSignature"
        >
          {{ $t("base.submit") }}
        </BaseButton>
      </div>
      <div
        v-if="mode === 'draw'"
        class="p-4"
      >
        <div class="mb-3 font-medium landscape-hint">
          {{ $t("signature.mobile.rotateScreen") }}
        </div>
        <InputDrawPad
          ref="inputDraw"
          :default-signature="signature"
          @onValidationChanged="bool => setFormValid(bool)"
        />
      </div>

      <div v-if="mode === 'upload'">
        <InputUpload
          ref="inputUpload"
          mobile-view="true"
          :default-signature="signature"
          @onValidationChanged="bool => setFormValid(bool)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store";
import { ref, computed, provide } from "vue";
import InputDrawPad from "../components/DocumentEditor/Signature/InputDrawPad";
import InputUpload from "../components/DocumentEditor/Signature/InputUpload";
import SignatureService from "../services/SignatureService";
import HeaderMessage from "../components/Common/HeaderMessage";
import IconClose from "../components/icons/IconClose";

export default {
  name: "MobileSign",
  components: {
    HeaderMessage,
    InputUpload,
    InputDrawPad,
    IconClose,
  },
  async beforeRouteEnter(to, from, next) {
    const mobileSignId = to.params.mobileSignId;
    const mode = to.params.mode;
    const hash = window.location.hash.substring(1);

    store.commit("SET_LOADING", true);

    /** Disable the next line to debug and style the MobileSignApp by calling
     * Debug URL: http://localhost:8080/m/XYZ/draw#123
     */
    await store.dispatch("mobileSign/authenticate", { mobileSignId, hash });
    await store.commit("mobileSign/setMode", mode);
    store.commit("SET_LOADING", false);
    next();
  },
  emits: ["close"],
  setup() {
    const isPending = ref(false);
    const isFormValid = ref(false);
    const submitted = ref(false);
    const canceled = ref(false);
    const signature = ref({});
    const mode = computed(() => store.state.mobileSign.mode);
    const inputDraw = ref("");
    const inputUpload = ref("");
    const errorCode = computed(() => store.state.mobileSign.error);
    provide("mobileMode", true);

    async function submit(signatureObj) {
      isPending.value = true;

      await SignatureService.submitMobileSignature(
        store.state.mobileSign.mobileSignId,
        store.state.mobileSign.hash,
        mode.value,
        signatureObj.blob
      );

      submitted.value = true;
      isPending.value = false;
    }

    async function cancel() {
      isPending.value = true;

      await SignatureService.cancelMobileSignature(
        store.state.mobileSign.mobileSignId,
        store.state.mobileSign.hash,
      );

      canceled.value = true;
      isPending.value = false;
    }

    function confirmSignature() {
      switch (mode.value) {
        case "draw":
          inputDraw.value.getSignatureObj(submit);
          break;
        case "upload":
          inputUpload.value.getSignatureObj(submit);
          break;
      }
    }
    function setFormValid(bool) {
      isFormValid.value = bool;
    }
    return {
      confirmSignature,
      isPending,
      isFormValid,
      signature,
      loaded: store.state.mobileSign.loaded,
      mode,
      submitted,
      canceled,
      inputDraw,
      inputUpload,
      errorCode,
      setFormValid,
      cancel,
    };
  },
};
</script>

<style scoped>
    .landscape-hint {
        @apply hidden;
    }
    @media screen and (orientation: portrait) {
        .landscape-hint {
            @apply block;
        }
    }
</style>
