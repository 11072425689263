<template>
  <div
    style="background: #f1f3f6"
    class="relative pb-32 md:pb-0 md:-mt-32"
  >
    <!-- kleiner Hack, weil deren Layout nicht komplett transparent ist, und wir sonst einen "cut" mit den Farben haben-->
    <div
      class="hidden w-full md:block bg-neutral-10"
      style="height: 100px"
    />

    <Loader
      v-if="initiating"
      text="Wir geladen..."
    />

    <iframe
      v-if="url"
      ref="iframe"
      :src="url"
      class="mt-0 md:mt-[-100px] w-full max-w-5xl mx-auto border-0"
      @load="onIframeLoaded"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import Loader from "../../components/Common/Loader";
import KYCService from "../../services/KYCService";

export default {
  components: {
    Loader,
  },
  setup() {
    const kycConfig = ref(null);
    const iframe = ref(null);
    const initiating = ref(true);
    const pending = ref(false);
    const store = useStore();

    async function getConfig() {
      const { data: config } = await KYCService.getKYCConfig(
        store.state.request.requestId
      );
      kycConfig.value = config;
    }

    onMounted(() => {
      console.log("VerificationKyc mounted");
      getConfig();
    });

    const url = computed(() => {
      if (!kycConfig.value) return null;

      let params = new URLSearchParams({
        layout: "embedded",
        sessionToken: kycConfig.value.token,
        layoutOff: "start_screen,menu_bar_logo,footer,end_screen",
      }).toString();

      return `${kycConfig.value.url}?${params}`;
    });

    const submit = async () => {
      pending.value = true;

      try {
        await KYCService.completeKYC(store.state.request.requestId);

        store.commit('auth/setKYCCompletedAt', new Date());
      } catch(err) {
        console.error(err);
      }

      pending.value = false;
    };

    const onIframeLoaded = () => {
      window.addEventListener("message", (message) => {
        if (message.source !== iframe.value.contentWindow) {
          return;
        }

        switch (message.data.messageType) {
          case "signd-document-height":
            iframe.value.style.height = `${message.data.data.value}px`;
            break;
          case "signd-auth-success":
            initiating.value = false;
            break;
          case "signd-verification-done":
            submit();
            break;
          case "signd-verification-failed":
          case "signd-auth-failed":
            // todo handle error
            break;
        }
      });
    };

    return {
      url,
      iframe,
      initiating,
      onIframeLoaded,
    };
  },
};
</script>
