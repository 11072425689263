export function isEmpty(obj) {
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
}

export function startLowerCase(string) {
  return string[0].toLowerCase() + string.substring(1);
}

export function startUpperCase(string) {
  return string[0].toUpperCase() + string.substring(1);
}

export function adjustColor(color, amount) {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export function formatRelativeTime(date, locale = 'de') {
    const d = new Date(date);
    const deltaDays = (d.getTime() - Date.now()) / (1000 * 3600 * 24);

    const unit = deltaDays > 1 ? 'day' : (deltaDays * 24 > 1 ? 'hour' : 'minute');
    const value = deltaDays > 1 ? deltaDays : (deltaDays * 24 > 1 ? deltaDays * 24 : deltaDays * 24 * 60);

    return new Intl.NumberFormat(locale, {style: 'unit', unit: unit, unitDisplay: 'long'}).format(Math.round(value));
}


export function formatDate(date, locale = 'de', customOptions = {}) {
    return date.toLocaleDateString(locale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        ...customOptions
    });
}
