<template>
  <div class="flex justify-center items-center w-full h-screen text-neutral-4">
    <div class="pb-24">
      <Loader
        :text="$t('document.loadingDocument')"
      />
    </div>
  </div>
</template>
<script>
import Loader from "../../components/Common/Loader";
export default {
	name: 'InitialLoading',
  components: {
    Loader
  },
}
</script>
