import {computed} from "vue";

export default function useDocuments(recipientId, request) {
  const isRequestDeclined = computed(() => {
    return !!request.declination;
  });

  const isDocToSign = (document, active = false) => {
    return !!document.recipients.find(
      recipient =>
        recipient.id === recipientId
        && recipient.type === "signatory"
        && (!active || recipient.status !== 'completed')
    );
  };

  const isDocPending = document => {
    return !!document.recipients.find(
      recipient =>
        recipient.id === recipientId &&
        recipient.type === "signatory" &&
        recipient.status === "pending"
    );
  };

  const isDocRecipientsPending = document => {
    return !!document.recipients.find(
      recipient =>
        recipient.type === "signatory" && recipient.status === "pending"
    );
  };

  const isDocToView = document => {
    return !!document.recipients.find(
      recipient =>
        recipient.id === recipientId && recipient.type !== "signatory" // 'recipient' or 'copy'
    );
  };

  /**
   * get all documents of user with type 'signatory'
   * @param documents the document received from the backend
   * @param active: if set to true, only so far unsigned documents are returned
   */
  const docsToSign = (documents, active = false) => {
    if (isRequestDeclined.value) {
      return [];
    }
    return documents.filter(doc => isDocToSign(doc, active));
  };

  // get all documents of user with type 'recipient' or 'copy
  const docsToView = (documents) => {
    if (isRequestDeclined.value) {
      return documents;
    }
    return documents.filter(doc => isDocToView(doc));
  };

  // get all documents of user with status 'pending'
  const docsPending = documents => {
    return documents.filter(doc => isDocPending(doc));
  };

  // get all documents with other recipients with status 'pending'
  const docsRecipientsPending = documents => {
    return documents.filter(doc => isDocRecipientsPending(doc));
  };

  return {
    docsToSign,
    docsToView,
    docsPending,
    docsRecipientsPending,
    isDocPending,
    isRequestDeclined
  };
}
