import tailwindConfig from "../../../tailwind.config";

export default function useTailwindTheme() {
  const config = tailwindConfig.theme;

  /* Color combinations used for UI badge elements */
  const badgeColors = {
    yellow: {
      text: config.colors.yellow["3"],
      background: config.colors.yellow["8"],
    },
    green: {
      text: config.colors.green["3"],
      background: config.colors.green["8"],
    },
    neutral: {
      text: config.colors.neutral["3"],
      background: config.colors.neutral["8"],
    },
  };

  /* Colors used for showing different pending states */
  const getPendingStateColors = pendingState => {
    const color = {
      text: badgeColors.neutral.text,
      background: badgeColors.neutral.background,
    };

    if (typeof pendingState !== "undefined" && pendingState !== null) {
      if (pendingState) {
        color.text = badgeColors.yellow.text;
        color.background = badgeColors.yellow.background;
      } else {
        color.text = badgeColors.green.text;
        color.background = badgeColors.green.background;
      }
    }

    return color;
  };

  /* All colors */
  const colors = config.colors;

  return {
    config,
    badgeColors,
    getPendingStateColors,
    colors,
  };
}
