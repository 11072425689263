<template>
  <modal
    @onCancel="$emit('onCancel')"
  >
    <template #title>
      <p class="rtl:text-right">
        {{ $t("document.decline.title") }}
      </p>
    </template>

    <template #description>
      <div class="rtl:text-right">
        {{ $t("document.decline.description") }}
      </div>
      <BaseTextArea
        v-model="declineReason"
        class="mt-4 placeholder-neutral-7 rtl:text-right"
        :placeholder="$t('document.decline.reason')"
      />
      <div
        v-if="errors && errors.length"
        class="p-3 my-4 text-sm text-red-600 bg-red-100 border border-red-300 rounded"
      >
        <p
          v-for="(error, index) in errors"
          :key="index"
        >
          &raquo; {{ error[0] }}
        </p>
      </div>
    </template>


    <template #buttons>
      <BaseButton
        small
        outlined
        @click="$emit('onCancel')"
      >
        {{ $t("base.cancel") }}
      </BaseButton>
      <BaseButton
        small
        warning
        :disabled="declineReason.length === 0"
        @click="$emit('onSubmit', declineReason)"
      >
        {{ $t("base.declineSignature") }}
      </BaseButton>
    </template>
  </modal>
</template>

<script>
import { ref } from "vue";
import Modal from "../../Common/Modal";
import BaseTextArea from '../../Base/BaseTextArea';

export default {
  components: {
    BaseTextArea,
    Modal,
  },
  props: {
    errors: {
      type: Object,
      required: false,
      default: () => {},
    }
  },
  emits: ["onCancel", "onSubmit"],
  setup() {
    const declineReason = ref("");

    return {
      declineReason,
    }
  }
};
</script>
