<template>
  <MenuItem
    v-slot="{ active }"
    as="div"
    class="_menu-item"
    @click="$emit('onClick')"
  >
    <div
      class="flex flex-row py-2 pr-4 pl-3 w-full"
      :class="[
        active ? activeStyle : '',
        secondary ? 'bg-neutral-4' : '',
      ]"
    >
      <BaseIcon>
        <slot name="icon" />
      </BaseIcon>
      <span class="ml-2">
        <slot />
      </span>
    </div>
  </MenuItem>
</template>
<script>
import { computed } from "vue";
import { MenuItem} from "@headlessui/vue";
export default {
  components: {
    MenuItem,
  },
  props: {
    secondary: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["onClick"],
  setup(props) {
    const activeStyle = computed(() => {
      return props.secondary ? 'bg-neutral-5' : 'bg-neutral-3';
    });

    return {
      activeStyle
    }
  }
}
</script>
<style>
@layer utilities {
  ._menu-item:first-child,
  ._menu-item:first-child > div {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  ._menu-item:last-child,
  ._menu-item:last-child > div {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
</style>
