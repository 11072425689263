<template>
  <div class="flex flex-col justify-center h-full">
    <BaseInput
      v-model="formattedFieldValue"
      type="text"
      disabled="true"
      :placeholder="field.placeholder"
      :style="fieldStyle"
      class="outline-none border-none bg-transparent focus:ring-0 focus:outline-none px-0.5 py-1"
      :class="[
        fieldStadium.disabled ? '' : '',
        fieldStadium.mine.todoOptional ? '' : '',
        fieldStadium.mine.todoRequired ? '' : '',
        fieldStadium.mine.done ? '' : '',
      ]"
    />
  </div>
</template>

<script>
import {ref, watch} from "vue";
import {useStore} from "vuex";
import {formatDate} from "./../../../functions/helpers";

export default {
  name: "DateField",
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldStadium: {
      type: Object,
      required: true,
    },
    fieldStyle: {
      type: Object,
      required: true,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const store = useStore();

    const formattedFieldValue = ref(
      props.field.value
        ? props.field.value
        : formatDate(new Date())
    );

    function valueUpdated(newVal) {
      store.dispatch("documentEditor/updateField", {
        fieldId: props.field.id,
        content: newVal,
      });
      emit('input', newVal);
    }

    valueUpdated(formattedFieldValue.value);

    watch(formattedFieldValue, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        valueUpdated(newVal)
      }
    });


    return {
      formattedFieldValue,
    };
  },
};
</script>
