<template>
  <div class="flex text-neutral-6 text-xs md:text-sm justify-between pt-1.5">
    <div>{{ document.name }}</div>
    <div>
      {{
        $t('document.pageCount', {
          currentPage: currentPage,
          pagesCount: document.pages.length,
        })
      }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
}
</script>
