<template>
  <BaseCheckBox
    v-model="checkboxInput"
    :disabled="fieldStadium.disabled || fieldStadium.readOnly"
    :style="fieldStyleObj"
    custom-input-style="-left-2.5"
    class="outline-none border-none bg-none focus:ring-0 focus:outline-none rounded-sm"
  />
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import eventBus from "../../../eventbus";

export default {
  name: "CheckboxField",
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldStadium: {
      type: Object,
      required: true,
    },
    fieldStyle: {
      type: Object,
      required: true,
    },
  },
  emits: ["onFocus"],
  setup(props, { emit }) {
    const store = useStore();
    const checkboxInput = ref(null);

    const fieldStyleObj = computed(() => {
      return {
        height: props.fieldStyle.height,
        marginTop: "-1px",
        marginLeft: "-1px",
      };
    });

    onMounted(() => {
      if (props.field.value) {
        checkboxInput.value = props.field.value;
      }
    });

    watch(checkboxInput, (newVal) => {
      if (newVal !== props.field.value) {
        store.dispatch("documentEditor/updateField", {
          fieldId: props.field.id,
          content: newVal,
        });

        emit("onFocus");
      }
    });

    eventBus.on('onTouchClicked', (id) => {
      if (props.field.id === id) {
        checkboxInput.value = !checkboxInput.value;
      }
    });

    return {
      checkboxInput,
      fieldStyleObj,
    };
  },
};
</script>
