<template>
  <div class="mt-36 flex justify-center items-center h-full">
    <HeaderMessage :text="$t('request.redirectMessage')" />
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import HeaderMessage from "../components/Common/HeaderMessage";
export default {
	name: "RedirectTo",
  components: { HeaderMessage },
  setup() {
    const route = useRoute();

    if (route.query.to) {
      window.location.href = route.query.to;
    } else {
      console.error('No param set');
    }
  }
}
</script>

<style scoped>

</style>
