<template>
  <g>
    <path
      class="st0"
      d="M15.6,1.8H5C4.7,1.8,4.5,2,4.5,2.5v19.4c0,0.1,0.2,0.4,0.5,0.4h14.6c0.3,0,0.5-0.2,0.5-0.4V6.5
		c0-0.3,0-0.3-0.1-0.4l-4.3-4.3C15.8,1.8,15.7,1.8,15.6,1.8z"
    />
    <polygon
      class="st1"
      points="15.8,1.8 15.8,6.2 20.1,6.2 	"
    />
    <path
      class="st2"
      d="M9.2,13.9L9.2,13.9c-0.1,0-0.2,0-0.4-0.1c-0.4-0.3-0.4-0.6-0.4-0.8c0.1-0.6,0.8-1.2,2.2-1.9
      c0.5-1.2,1.1-2.7,1.4-3.9c-0.4-0.8-0.7-1.8-0.5-2.4c0.1-0.2,0.2-0.4,0.4-0.4c0.1,0,0.3-0.1,0.4-0.1c0.2,0,0.3,0.2,0.5,0.4
      c0.1,0.1,0.4,0.4-0.1,2.5c0.5,1,1.2,2.1,1.9,2.8c0.5-0.1,0.9-0.1,1.2-0.1c0.6,0,0.9,0.1,1.1,0.4c0.1,0.2,0.1,0.5-0.1,0.8
      c-0.2,0.3-0.5,0.4-0.8,0.4c-0.4,0-1-0.3-1.5-0.8c-1,0.2-2.2,0.6-3.2,1c-0.3,0.6-0.6,1.2-0.9,1.6C9.9,13.7,9.5,13.9,9.2,13.9z
       M10.2,12.1c-0.8,0.4-1.1,0.8-1.1,1c0,0,0,0.1,0.2,0.3C9.3,13.3,9.6,13.2,10.2,12.1z M15.2,10.4c0.3,0.2,0.4,0.3,0.6,0.3
      c0.1,0,0.3,0,0.4-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0,0-0.1-0.1-0.4-0.1C15.7,10.4,15.4,10.4,15.2,10.4z M12.5,8c-0.3,0.9-0.6,1.9-1,2.8
      c0.8-0.3,1.6-0.6,2.4-0.7C13.4,9.5,12.9,8.8,12.5,8z M12.2,5c0,0-0.5,0.6,0,1.2C12.6,5.4,12.2,4.9,12.2,5z"
    />
    <path
      class="st2"
      d="M19.6,22.2H5c-0.3,0-0.5-0.2-0.5-0.5V16h15.7v5.7C20.2,22,19.9,22.2,19.6,22.2z"
    />
    <g>
      <path
        class="st3"
        d="M8.4,21.1H7.8v-3.7h1.1c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.4
			c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.1H8.4
			L8.4,21.1L8.4,21.1z M8.4,17.9v1.5H9c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.4
			c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.2C9.4,18.1,9.4,18,9.3,18c-0.1,0-0.2-0.1-0.4-0.1L8.4,17.9L8.4,17.9z"
      />
      <path
        class="st3"
        d="M13.9,19.2c0,0.3,0,0.6-0.1,0.8c-0.1,0.2-0.1,0.4-0.2,0.5s-0.2,0.3-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.2
			c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2,0h-1.4v-3.7H12c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.3,0.3,0.6
			C13.8,18.8,13.9,19,13.9,19.2z M12.1,20.7c0.4,0,0.7-0.1,0.9-0.4c0.2-0.3,0.3-0.6,0.3-1.1c0-0.2,0-0.3-0.1-0.5
			c0-0.1-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.7-0.1h-0.3v2.8C11.5,20.7,12.1,20.7,12.1,20.7z"
      />
      <path
        class="st3"
        d="M15.3,17.9v1.2h1.5v0.4h-1.5v1.7h-0.6v-3.7H17v0.5H15.3z"
      />
    </g>
  </g>
</template>

<style scoped>
.st0{fill:#E9E9E0;}
.st1{fill:#D9D7CA;}
.st2{fill:#CC4B4C;}
.st3{fill:#FFFFFF;}
</style>
