import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import i18n from "../i18n";
import SigningApp from "../views/SigningApp.vue";
import DocumentEditor from "../views/DocumentEditor.vue";
import MobileSignApp from "../views/MobileSignApp";
import Redirect from "../views/RedirectTo";

const baseURI = "/r/:requestId/:recipientId";

const routes = [
  {
    path: baseURI,
    name: "SigningApp",
    component: SigningApp,
    props: true,
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    beforeEnter: (to, from, next) => {
      store.commit("SET_ERROR", {
        message: to.path.startsWith('/r/') ? i18n.global.t("base.requestNotFound") : i18n.global.t("base.pageNotFound"),
      });
      next();
    },
  },
  {
    path: `${baseURI}/d/:documentId`,
    name: "DocumentEditor",
    component: DocumentEditor,
  },
  {
    path: `/m/:mobileSignId/:mode`,
    name: "MobileSignApp",
    component: MobileSignApp,
  },
  {
    path: `/redirect`,
    name: "Redirect",
    component: Redirect,
    beforeEnter: (to, from, next) => {
      if (!store.state.request.request) {
        store.commit("SET_ERROR", {
          message: to.path.startsWith('/r/') ? i18n.global.t("base.requestNotFound") : i18n.global.t("base.pageNotFound"),
        });
      }
      next();
    },

  },
];

const router = createRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(),
  routes,
});

export default router;
