<template>
  <div
    class="relative z-10 pb-4 md:pb-6 md:pt-2"
  >
    <div
      class="flex items-center space-x-6 md:space-x-8"
      :class="{'justify-center': messageOnly}"
    >
      <div
        :style="`border-color:${theme.primaryBgColor}`"
        class="py-3 px-4 text-lg font-medium leading-tight bg-white rounded-md border-l-4 shadow rtl:text-right md:py-3 md:px-8 md:border-l-8 md:text-xl"
      >
        {{ text }}
      </div>
      <img
        :alt="`${theme.name} logo'`"
        :src="theme.logoDark"
        class="hidden w-14 h-auto md:flex md:w-24"
      >
    </div>
    <div
      v-if="caption"
      class="mt-4 text-base text-neutral-4 md:text-center"
    >
      {{ caption }}
    </div>
    <!-- remove for now: bei Unklarheiten kontaktieren Sie bitte den Absender
    <div class="flex justify-center pt-16 _caption">
        <div class="md:max-w-full w-772 max-w-708 text-neutral-5">

        <div v-if="sender">
            {{ t('request.contactSender') }}
            <a class="_link" :style="`color:${theme.primaryBgColor}`" :href="`mailto:${sender.email}`">
                {{ t('request.sender') }}.
            </a>
        </div>

            <slot></slot>
        </div>
    </div>
    -->
  </div>
</template>

<script>
import useTheme from "../../composables/LayoutAndStyle/useTheme";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      required: false,
      default: ""
    },
    sender: {
      type: Object,
      required: false,
      default: () => {},
    },
    messageOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const { theme } = useTheme();
    return {
      theme,
    };
  },
};
</script>
