<template>
  <textarea
    ref="inputRef"
    v-bind="$attrs"
    :placeholder="placeholder"
    :value="modelValue"
    class="w-full overflow-hidden text-lg placeholder-current border-2 rounded-md resize-none rtl:text-right disabled:cursor-not-allowed focus:outline-none focus:border-primary-6 border-neutral-9"
    style="max-lines: 1"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
import { ref } from "vue";
export default {
  name: "BaseTextArea",
  props: {
    placeholder: {
      type: String,
      default: "",
      required: false,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    }
  },
  emits: ["update:modelValue"],
  setup() {
    const inputRef = ref(null);

    function focusInput() {
      inputRef.value.focus();
    }
    return {
      inputRef,
      focusInput
    }
  }
};
</script>

<style scoped>
</style>
