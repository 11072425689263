<template>
  <FadeTransition>
    <div class="s-default-page-spacing s-default-flex-content">
      <docs-last-action-feedback
        :are-docs-to-sign-left="docsToSign(request.documents, true).length > 0"
      />

      <FadeTransition>
        <ProgressBar v-if="securityLevel !== 'basic' && docsPending(docsToSign(request.documents)).length" />
      </FadeTransition>

      <HeaderMessage
        :text="headerText"
        :sender="request.sender"
      />

      <SenderMessage :request="request" />

      <div
        v-if="isRequestDeclined"
        class="flex py-2 px-3 mb-4 text-base font-medium rounded-md bg-yellow-8 text-yellow-1 md:mr-32"
      >
        <BaseIcon class="mr-2">
          <IconCloseCircle />
        </BaseIcon>
        {{ $t('request.declinedInfo') }}
      </div>

      <!-- Documents which need to be signed by recipient  -->
      <DocumentCardList
        v-if="docsToSign(request.documents).length"
        :request="request"
        :documents="docsToSign(request.documents)"
      />

      <!-- Documents which DO NOT need to be signed by recipient -->
      <DocumentCardList
        v-if="docsToView(request.documents).length"
        :request="request"
        :documents="docsToView(request.documents)"
        type="toView"
      />
    </div>
  </FadeTransition>
</template>

<script>
import { onMounted, onUnmounted } from 'vue';
import {useStore} from "vuex";
import useRequest from "../../composables/Request/useRequest";
import useDocuments from "../../composables/Request/useDocuments";
import eventBus from "../../eventbus";

import ProgressBar from "../../components/Common/ProgressBar";
import HeaderMessage from "../../components/Common/HeaderMessage.vue";

import SenderMessage from "../../components/DocumentOverview/SenderMessage.vue";
import DocumentCardList from "../../components/DocumentOverview/DocumentCardList.vue";
import DocsLastActionFeedback from "../../components/DocumentOverview/DocsLastActionFeedback";
import IconCloseCircle from "../../components/icons/IconCloseCircle";
import FadeTransition from "../../components/Common/Transitions/FadeTransition";

export default {
  components: {
    IconCloseCircle,
    ProgressBar,
    DocsLastActionFeedback,
    HeaderMessage,
    SenderMessage,
    DocumentCardList,
    FadeTransition,
  },
  props: {
    requestId: {
      type: String,
      default: "",
    },
    recipientId: {
      type: String,
      default: ""
    },
  },
  async setup(props) {
    onMounted(() => {
      eventBus.emit("showBackgroundAnimation", false);
    });

    onUnmounted(() => {
      eventBus.emit("showBackgroundAnimation", true);
    });

    const store = useStore();
    const { getHeaderText } = await useRequest();
    const securityLevel = store.state.request.securityLevel;
    let request = store.state.request.request;

    const { docsToSign, docsToView, docsPending, isRequestDeclined } = useDocuments(
      props.recipientId,
      request
    );
    const headerText = getHeaderText(request, docsToSign(request.documents));
    const logo = require("../../assets/svgs/logos/logo-default.svg");


    return {
      logo,
      docsToSign,
      docsToView,
      docsPending,
      request,
      headerText,
      isRequestDeclined,
      securityLevel,
    };
  },
};
</script>
