import { computed, ref } from "vue";
import { useDisplayedPageSize } from "./useDisplayedPageSize";

/**
 * Calculates the current position + width of the @field.
 * To ensure that the field gets resized when changing the screen make sure to call @mountMe and @unmountMe method
 */
export default function useFieldLayout(field, page, isSmallScreen) {
  const fieldX = ref(-1);
  const fieldY = ref(-1);
  const fieldWidth = ref(-1);
  const fieldHeight = ref(-1);

  /* needed for calculating fieldMetaStyle */
  const calculatedPageWidth = ref(-1);

  /* set min widths/heights for fields */
  const minFieldWidth = isSmallScreen.value ? 0 : 15;
  const minFieldHeight = isSmallScreen.value ? 0 : 15;

  function calculateX(page, displayedPageWidth) {
    return (field.x / page.width) * displayedPageWidth;
  }

  function calculateY(page, displayedPageHeight) {
    return (field.y / page.height) * displayedPageHeight;
  }

  function calculateWidth(page, displayedPageWidth) {
    const calculatedFieldWidth = field.width / page.width * displayedPageWidth;
    if (calculatedFieldWidth > minFieldWidth) {
      return calculatedFieldWidth;
    }
    return minFieldWidth;
  }

  function calculateHeight(page, displayedPageHeight) {
    const calculatedFieldHeight = field.height / page.height * displayedPageHeight;
    if (calculatedFieldHeight > minFieldHeight) {
      return calculatedFieldHeight;
    }
    return minFieldHeight;
  }

  function recalcDimensions(page) {
    const {
      calculateDisplayWidth,
      calculateDisplayHeight,
    } = useDisplayedPageSize(page.width, page.height, false);

    const displayWidth = calculateDisplayWidth();
    const displayHeight = calculateDisplayHeight();
    fieldX.value = calculateX(page, displayWidth);
    fieldY.value = calculateY(page, displayHeight);
    fieldWidth.value = calculateWidth(page, displayWidth);
    fieldHeight.value = calculateHeight(page, displayHeight);

    /* needed for calculating fieldMetaStyle */
    calculatedPageWidth.value = displayWidth;

    field.calculated = {
      fieldX: fieldX.value,
      fieldY: fieldY.value,
      fieldHeight: fieldHeight.value,
      fieldWidth: fieldWidth.value,
    };

    return {
      fieldX,
      fieldY,
      fieldWidth,
      fieldHeight,
    };
  }

  const recalcDimensionsListener = () => {
    recalcDimensions(page);
  };

  function mountMe() {
    if (page) {
      recalcDimensions(page);

      window.addEventListener("resize", recalcDimensionsListener);
    }
  }

  function unmountMe() {
    window.removeEventListener("resize", recalcDimensionsListener);
  }

  const fieldDimensions = computed(() => {
    return {
      fieldX,
      fieldY,
      fieldWidth,
      fieldHeight,
    };
  });

  const fieldDimensionsHtml = computed(() => {
    return {
      left: `${fieldX.value}px`,
      top: `${fieldY.value}px`,
      width: `${fieldWidth.value}px`,
      height: `${fieldHeight.value}px`,
    };
  });

  const fieldMetaStyleHtml = computed(() => {
    const minFontSize = isSmallScreen.value ? 10 : 14;

    const calculatedFontSize = (calculatedPageWidth.value / page.width * field.meta.font_size); // <-- temporary solution
    // const calculatedFontSize = (calculatedPageWidth.value / 800 * field.meta.font_size); // <-- temporary solution
    const displayedFontSize = calculatedFontSize > minFontSize ? calculatedFontSize : minFontSize;
    return {
      fontSize: `${displayedFontSize}px`,
      lineHeight: `${displayedFontSize * 1.4}px`,
      fontFamily: `${field.meta.font}`,
      fontWeight: field.meta.bold ? "bold" : "",
      fontStyle: field.meta.italic ? "italic" : "",
      textDecoration: field.meta.underline ? "underline" : "",
    };
  });

  return {
    recalcDimensions,
    fieldDimensions,
    fieldDimensionsHtml,
    fieldMetaStyleHtml,
    mountMe,
    unmountMe,
  };
}

export const htmlFieldId = field => "field-id-" + field.id;


/**
 * Sorts the fields so that browser selectors (via tab) continue in a nice order
 * By ordering fields they will be rendered from top to bottom, this is necessary as the creator of the document
 * might have not added fields in an ordered way
 *
 *
 * Examples of round function
 * round(51,  20, 10) = 70
 * round(70,  20, 10) = 70
 * round(99,  20, 10) = 110
 * round(100, 20, 10) = 110
 *
 * @param a field
 * @param b field
 * @return order logic
 */
export const sortFields = (a, b) => {
  if (a.page !== b.page) {
    return a.page < b.page ? -1 : 1;
  }
  function round(number, increment, offset) {
    return Math.ceil((number - offset) / increment) * increment + offset;
  }

  const roundedA = round(a.y, 20, 0);
  const roundedB = round(b.y, 20, 0);

  if (roundedA === roundedB) {
    return a.x - b.x;
  }

  return a.y - b.y;
};

