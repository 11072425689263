<template>
  <form class="flex flex-col">
    <div class="relative flex flex-col w-full mb-4">
      <div>
        <BaseLabel :label="t('base.smsCode')" />
      </div>
      <CodeInput v-model="code" />
      <ErrorMessages :error-messages="codeErrors" />
    </div>

    <div>
      <BaseButton
        class="mt-2"
        :on-click="onSubmit"
        :style="`background:${theme.primaryBgColor}`"
        type="submit"
        :loading="loading"
        :disabled="!valid"
      >
        <span>
          {{ t("base.submit") }}
        </span>
      </BaseButton>

      <BaseButton
        type="button"
        class="ml-6 font-medium cursor-pointer"
        text-link
        @click.prevent="$emit('cancel')"
      >
        {{ t("base.cancel") }}
      </BaseButton>
    </div>
  </form>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useForm, useField } from "vee-validate";
import { object, string, setLocale } from "yup";
import { isEmpty } from "../../functions/helpers.js";

import eventBus from "../../eventbus";
import useTheme from "../../composables/LayoutAndStyle/useTheme";
import useFormValidation from "../../composables/useFormValidation";
import svgs from "../../assets/svgs/index.js";

import CodeInput from "./CodeInput";
import ErrorMessages from '../Form/ErrorMessages';

export default {
  components: {
    ErrorMessages,
    CodeInput,
  },
  emits: ["cancel", "continueStep"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { theme } = useTheme();
    const { locale } = useFormValidation();
    const store = useStore();
    const loading = ref(false);
    /* sets translation strings */
    setLocale(locale);
    /* sets validation rules */
    const fields = {
      code: string().required(),
    };
    const schema = object(fields);
    const { handleSubmit, setFieldError, errors } = useForm({
      validationSchema: schema,
    });

    const valid = computed(() => {
      return isEmpty(errors.value);
    });

    const { errors: codeErrors, value: code } = useField("code");


    async function completeMFA(values) {
      loading.value = true;

      try {
        await store.dispatch('auth/completeMFA', {
          code: values.code,
        });

        // reset state to trigger animation to fade out
        store.commit("auth/setCompletedSignteqVerification", null);
        // set timeout to give animation time to finish
        setTimeout(() => {
          store.commit("auth/setCompletedSignteqVerification", true);
        }, 200);

        emit("continueStep", null);
      } catch (error) {
        const { errors } = error.response.data;
        Object.keys(errors).forEach(function (key) {
          setFieldError("code", errors[key]);
        });
      } finally {
        loading.value = false;
      }
    }

    const onSubmit = handleSubmit(async values => await completeMFA(values));

    onMounted(() => {
      eventBus.emit("show-notification", {
        message: t("notification.smsSent"),
        icon: svgs.sms,
      });
    });

    return {
      t,
      theme,
      code,
      codeErrors,
      onSubmit,
      valid,
      loading,
    };
  },
};
</script>
