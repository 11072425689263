<template>
  <path
    :style="`fill: ${background}`"
    d="M8,0.5c4.1,0,7.5,3.3,7.5,7.5s-3.3,7.5-7.5,7.5S0.5,12.1,0.5,8S3.9,0.5,8,0.5z"
  />
  <path
    :style="`fill: ${colors.neutral[1]}`"
    d="M7.3,8C6.8,8,6.5,7.7,6.5,7.3c0-0.4,0.3-0.7,0.7-0.7h1.5c0.4,0,0.7,0.3,0.7,0.7c0,0.1,0,0.1,0,0.2l-1.2,4.3h0.5 c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7H7.3c-0.4,0-0.7-0.3-0.7-0.7c0-0.1,0-0.1,0-0.2L7.8,8H7.3z M8.7,5 C8.1,5,7.6,4.5,7.6,3.9s0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1S9.4,5,8.7,5z"
  />
</template>
<script>
import { ref } from "vue";
import useTailwindTheme from "../../composables/LayoutAndStyle/useTailwindTheme";

export default {
  props: {
    bgColor: {
      type: String,
      required: false,
      default: null,
    }
  },
  setup(props) {
    const { colors } = useTailwindTheme();
    const background = ref(props.bgColor);

    if (!background.value) {
      background.value = colors.neutral[9];
    }
    return {
      colors,
      background,
    }
  },
}
</script>

