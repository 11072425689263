import { ref, watchEffect, watch } from "vue";
import router from "../../router";
import DocumentService from "../../services/DocumentService";
import usePromise from "../../composables/usePromise";
// import { useStore } from "vuex";

export default function useDocument(requestId, documentId, store) {
  // const store = useStore();
  const loading = ref(false);

  function showDocument() {
    const getDocument = usePromise(() =>
      DocumentService.getDocument(requestId, documentId)
    );
    getDocument.createPromise();

    // watch changes of loading state
    watchEffect(() => {
      loading.value = getDocument.loading.value;
    });

    /*
     * store document and route to document viewer when data ready fetched
     */
    watch(getDocument.result, () => {
      console.log("New active document", getDocument.result.value.data);
      store.dispatch(
        "documents/setActiveDocument",
        getDocument.result.value.data
      );
      router.push({
        name: "DocumentEditor",
        params: { documentId },
      });
    });
  }

  return { loading, showDocument };
}
