<template>
  <BaseLabel
    :for="$attrs.id"
    :label="label"
    class="mb-0.5"
  />

  <!-- TODO: move md:max-w-sm into baseInput and override custom widths on fieldTypes level -->
  <BaseInput
    v-bind="$attrs"
    class="md:max-w-sm py-1 px-2 font-medium placeholder-neutral-7"
    :class="errorMessages.length ? 'border-yellow-6' : ''"
  />
  <ErrorMessages
    v-if="errorMessages.length"
    :error-messages="errorMessages"
  />
  <span
    v-else
    class="pt-1 text-sm text-neutral-6"
  >
    {{ labelInfo }}
  </span>
</template>

<script>
import ErrorMessages from './ErrorMessages';
export default {
  components: {
    ErrorMessages
  },
  props: {
    label: {
      type: String,
      default: "",
      required: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
      required: true,
    },
    labelInfo: {
      type: String,
      default: "",
      required: false
    },
  }
}
</script>
