<template>
  <circle
    cx="12"
    cy="12"
    r="10"
  />
  <path
    :style="`fill:${bgColor}`"
    d="M13.41 12l2.83 2.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 1 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12z"
  />
</template>

<script>
export default {
  name: "CloseCircleIcon",
  props: {
    bgColor: {
      type: String,
      required: false,
      default: "#FCE588",
    }
  }
};
</script>
