export default function useSignatureFonts() {
  const rawFonts = [
    { class: "dawning_of_a_new_day", font: "Dawning of a New Day" },
    { class: "herr_von_muellerhoff", font: "Herr Von Muellerhoff" },
    { class: "la_belle_aurore", font: "La Belle Aurore" },
    { class: "mr_de_haviland", font: "Mr De Haviland" },
    { class: "nothing_you_could_do", font: "Nothing You Could Do" },
    { class: "over_the_rainbow", font: "Over the Rainbow" },
    { class: "zeyada", font: "Zeyada" },
  ];

  return {
    rawFonts,
  };
}
