import { computed } from "vue";
import { useStore } from "vuex";

export default function useTheme() {
  const store = useStore();
  const localization = computed(() => {
    return store.state.request.localization;
  });

  return {
    localization,
  };
}
