<template>
  <path
    fill="currentColor"
    d="M8,1.5c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5S1.5,11.6,1.5,8S4.4,1.5,8,1.5z M4.2,2.9l5.1-1.2l5.1,1.2v5.7
	c0,1.9-1.1,3.6-2.8,4.5l-2.3,1.1L7,13c-1.7-0.8-2.8-2.5-2.8-4.4V2.9z"
  />
  <g>
    <path
      :fill="color"
      d="M7.8,7.4C7.4,7.4,7,7.2,6.7,6.9C6.4,6.6,6.2,6.2,6.2,5.8c0-0.4,0.2-0.8,0.5-1.1C7,4.3,7.4,4.1,7.8,4.1
		c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.1c0,0.4-0.2,0.8-0.5,1.1C8.7,7.2,8.2,7.4,7.8,7.4z"
    />
    <path
      :fill="color"
      d="M10.6,10.6c0,0.4-0.4,0.8-0.8,0.8h-4C5.4,11.4,5,11,5,10.6v-0.4c0-1.1,0.9-2,2-2h1.6c1.1,0,2,0.9,2,2V10.6z"
    />
    <path
      :fill="color"
      d="M11.8,7.7h-0.7c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1v0.7c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0h0.7c0,0,0.1,0,0.1,0
		c0,0,0-0.1,0-0.1V7.8C11.9,7.8,11.9,7.7,11.8,7.7C11.8,7.7,11.8,7.7,11.8,7.7z"
    />
    <path
      :fill="color"
      d="M12.7,5.6c-0.1-0.1-0.2-0.3-0.3-0.4C12.3,5.1,12.1,5.1,12,5c-0.2-0.1-0.3-0.1-0.5-0.1c-0.6,0-1,0.3-1.3,0.8
		c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l0.5,0.4c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0C11,6,11.1,5.9,11.1,5.8c0.1-0.1,0.2-0.1,0.3-0.1
		c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c0,0.1-0.1,0.1-0.2,0.2c-0.2,0.1-0.3,0.2-0.4,0.3
		C11,6.9,11,7,11,7.2v0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1h0.7c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.2
		C12,7.1,12.1,7,12.2,7c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2
		c0-0.1,0-0.2,0-0.3C12.8,5.9,12.8,5.8,12.7,5.6z"
    />
  </g>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>
