<template>
  <a
    :href="url"
    target="_blank"
    class="hover:text-black cursor-pointer"
  >
    {{ text }}
  </a>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      required: false,
      default: "",
    },
    text: {
      type: String,
      required: true,
    }
  }
}
</script>