<template>
  <div
    class="hidden items-center md:flex select-none"
  >
    <FieldsToSignInfo
      v-if="recipientDocumentRelationship === 'pending' && !isReadOnly"
    />
    <!-- Back Button -->
    <BackLink v-else />
  </div>
  
  <div class="flex justify-between items-center space-x-4 w-full md:justify-start md:w-auto">
    <!-- Dropown Menu Actions -->
    <DropDownMenuButton :position="isSmallScreen ?'right' : 'left'">
      <!-- Decline Request -->
      <DropDownMenuButtonItem
        v-if="recipientDocumentRelationship === 'pending' && !isReadOnly"
        @onClick="$emit('showDeclineModal')"
      >
        <template #icon>
          <IconCloseCircle :bg-color="colors.neutral[1]" />
        </template>
        <template #default>
          {{ $t("request.declineRequest") }}
        </template>
      </DropDownMenuButtonItem>

      <!-- Close Document -->
      <DropDownMenuButtonItem
        @onClick="goBack"
      >
        <template #icon>
          <IconExit />
        </template>
        <template #default>
          {{ $t("document.closeDocument") }}
        </template>
      </DropDownMenuButtonItem>

      <!-- Download -->
      <DropDownMenuButtonItem
        v-if="isReadOnly"
        @onClick="downloadDocument"
      >
        <template #icon>
          <IconDownload />
        </template>
        <template #default>
          {{ $t("document.downloadDocument") }}
        </template>
      </DropDownMenuButtonItem>


      <!-- Hilfe und Support -->
      <DropDownMenuButtonItem
        v-if="theme.showToolbarLinks"
        secondary
        @onClick="supportButtonClicked"
      >
        <template #icon>
          <IconExternal />
        </template>
        <template #default>
          {{ $t("document.supportLink") }}
        </template>
      </DropDownMenuButtonItem>

      <!-- Datenschutz und Bestimmungen -->
      <DropDownMenuButtonItem
        v-if="theme.showToolbarLinks"
        secondary
        @onClick="dataProtectionButtonClicked"
      >
        <template #icon>
          <IconExternal />
        </template>
        <template #default>
          {{ $t("document.dataProtectionLink") }}
        </template>
      </DropDownMenuButtonItem>

      <!-- Informationen zu signteq -->
      <DropDownMenuButtonItem
        v-if="theme.showToolbarLinks"
        secondary
        @onClick="informationButtonClicked"
      >
        <template #icon>
          <IconExternal />
        </template>
        <template #default>
          {{ $t("document.informationLink") }}
        </template>
      </DropDownMenuButtonItem>

      <!-- Sprache ändern -->
      <DropDownMenuButtonItem
        secondary
        @onClick="changeLanguage"
      >
        <template #icon>
          <LanguageIconSelector :language="useMultiLang.activeLanguage.value" />
        </template>
        <template #default>
          {{ $t("base.changeLanguage") }}
        </template>
      </DropDownMenuButtonItem>
    </DropDownMenuButton>

    <!-- Signing Button Start/Continue/Complete -->
    <div
      v-if="!isReadOnly"
      class="flex flex-row"
    >
      <FieldsToSignInfo
        v-if="recipientDocumentRelationship === 'pending'"
        class="flex md:hidden mr-3.5"
      />
      
      <BaseButton
        small
        :on-click="signButtonClicked"
        :success="signDocumentState === 'sign'"
      >
        <template #icon>
          <BaseIcon>
            <IconEdit />
          </BaseIcon>
        </template>
        <template #default>
          <span v-if="signDocumentState === 'sign'">
            {{ $t("request.complete") }}
          </span>
          <span v-else-if="signDocumentState === 'start'">
            {{ $t("base.start") }}
          </span>
          <span v-if="signDocumentState === 'continue'">
            {{ $t("base.continue") }}
          </span>
        </template>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import router from "../../router";
import eventBus from '../../eventbus';
import useTheme from "../../composables/LayoutAndStyle/useTheme";
import useTailwindTheme from "../../composables/LayoutAndStyle/useTailwindTheme";
import useMultiLanguages from "../../composables/useMultiLanguages";
import useBreakPoints from "../../composables/LayoutAndStyle/useBreakPoints";
import FieldsToSignInfo from "./FieldsToSignInfo";
import IconEdit from "../icons/IconEdit";
import IconCloseCircle from "../icons/IconCloseCircle";
import IconDownload from "../icons/IconDownload";
import IconExit from "../icons/IconExit";
import IconExternal from "../icons/IconExternal";
import DropDownMenuButton from '../Common/Navigation/DropDownMenuButton';
import DropDownMenuButtonItem from '../Common/Navigation/DropDownMenuButtonItem';
import BackLink from '../Common/Navigation/BackLink';
import LanguageIconSelector from "../Common/Language/LanguageIconSelector";

export default {
  components: {
    LanguageIconSelector,
    DropDownMenuButton,
    DropDownMenuButtonItem,
    FieldsToSignInfo,
    BackLink,
    IconEdit,
    IconCloseCircle,
    IconExit,
    IconDownload,
    IconExternal,
  },
  emits: ["showConfirm", "showDeclineModal", "showLanguageModal"],
  setup(_, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const { colors } = useTailwindTheme();
    const useMultiLang = useMultiLanguages();
    const { theme } = useTheme();
    const { isSmallScreen } = useBreakPoints();

    const recipientDocumentRelationship = computed(() => {
      return store.getters["documents/currentRecipientDocumentRelationship"];
    });
    const isReadOnly = computed(() => {
      return store.getters["documents/isReadOnlyMode"];
    });

    const signDocumentState = computed(() => {
      return store.getters["documentEditor/getSignDocumentState"];
    });

    function signButtonClicked() {
      if (
        signDocumentState.value === "start" ||
        signDocumentState.value === "continue"
      ) {
        eventBus.emit("resumeNextFieldMode");
      }
      if (signDocumentState.value === "sign") {
        emit("showConfirm");
      }
    }

    function supportButtonClicked() {
      window.open(t('urls.contact'), '_blank');
    }

    function informationButtonClicked() {
      window.open(t('urls.signteqLP'), '_blank');
    }

    function dataProtectionButtonClicked() {
      window.open(t('urls.dataSecurityPolicies'), '_blank');
    }

    function downloadDocument() {
      window.open(store.state.documents.currentDocument.download_url, '_blank');
    }

    function changeLanguage() {
      emit("showLanguageModal");
    }

    function goBack() {
      if (signDocumentState.value === "start") {
        router.back();
        return;
      }

      const r = confirm(t('document.abort.closeTabHint'));
      if (r === true) {
        router.back();
      }
    }

    return {
      router,
      recipientDocumentRelationship,
      isReadOnly,
      signDocumentState,
      goBack,
      signButtonClicked,
      supportButtonClicked,
      informationButtonClicked,
      dataProtectionButtonClicked,
      colors,
      downloadDocument,
      changeLanguage,
      useMultiLang,
      theme,
      isSmallScreen,
    }
  }
}
</script>
