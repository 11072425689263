<template>
  <ul class="flex mb-3 space-x-2 overflow-auto border-b border-none">
    <SignatureTab
      v-if="signatureSettings.allow_draw"
      :is-active="activeTab === 'draw'"
      :on-click="() => $emit('setTab', 'draw')"
    >
      <div class="flex items-center">
        <BaseIcon
          class="hidden mr-2 md:flex"
        >
          <IconSignature />
        </BaseIcon>
        <span class="text-sm md:text-base">
          {{ t("signature.tabDraw") }}
        </span>
      </div>
    </SignatureTab>

    <SignatureTab
      v-if="signatureSettings.allow_type"
      :is-active="activeTab === 'type'"
      :on-click="() => $emit('setTab', 'type')"
    >
      <div class="flex items-center">
        <BaseIcon
          size="20"
          class="hidden mr-2 md:flex"
        >
          <IconText />
        </BaseIcon>
        <span class="text-sm md:text-base">
          {{ t("signature.tabType") }}
        </span>
      </div>
    </SignatureTab>

    <SignatureTab
      v-if="signatureSettings.allow_upload"
      :is-active="activeTab === 'upload'"
      :on-click="() => $emit('setTab', 'upload')"
    >
      <div class="flex">
        <BaseIcon
          size="20"
          class="hidden mr-2 md:flex"
        >
          <IconUpload />
        </BaseIcon>
        <span class="text-sm md:text-base">
          {{ t("signature.tabUpload") }}
        </span>
      </div>
    </SignatureTab>

    <SignatureTab
      v-if="!isMobile && signatureSettings.allow_mobilephone"
      :is-active="activeTab === 'mobile'"
      :on-click="() => $emit('setTab', 'mobile')"
    >
      <div class="flex">
        <BaseIcon
          size="20"
          class="hidden mr-2 md:flex"
        >
          <IconMobile />
        </BaseIcon>

        <span class="text-sm md:text-base">
          {{ t("signature.tabMobile") }}
        </span>
      </div>
    </SignatureTab>
  </ul>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SignatureTab from "./SignatureTab";
import useDeviceDetection from "../../../composables/LayoutAndStyle/useDeviceDetection";
import IconUpload from "../../icons/IconUpload";
import IconSignature from "../../icons/IconSignature";
import IconText from "../../icons/IconText";
import IconMobile from "../../icons/IconMobile";
export default {
  name: "SignatureTabs",
  components: {
    IconMobile,
    IconText,
    IconSignature,
    IconUpload,
    SignatureTab
  },
  props: {
    activeTab: {
      type: String,
      required: true,
    },
  },
  emits: ["setTab"],
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { isMobile } = useDeviceDetection();
    let signatureSettings = store.state.request.signatureSettings;

    return {
      signatureSettings,
      isMobile,
      t,
    };
  },
};
</script>
