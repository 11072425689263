<template>
  <div
    class="flex items-center justify-center "
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :width="20"
      :height="20"
      class="mr-1"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="currentColor"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
    
    <span
      v-if="showText"
      class="text-base"
    >{{ loadingText }}</span>
  </div>
</template>
<script>
/* Loader Component which gets its color through currentColor css attr. */
import { ref } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: 'InitialLoading',
  props: {
    text: {
      type: String,
      default: "",
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const loadingText = ref(props.text);
    if (!props.text) {
      loadingText.value = t('base.loading');
    }
    return { loadingText };
  }
}
</script>
