<template>
  <div
    class="mb-2 border-t-0 text-neutral-1 border-neutral-1"
    :class="type"
  >
    <div>
      <div class="flex flex-col md:space-x-6 md:flex-row md:items-center">
        <h3 class="text-3xl font-medium md:text-4xl">
          {{ type === "toSign" ? t("request.toSign") : t("request.toView") }}
        </h3>
      </div>
      
      <div class="space-x-3">
        <div
          v-if="type === 'toSign'"
          class="flex mt-1.5 "
          :class="docsPending(documents).length ? 'text-yellow-3' : 'text-green-3'"
        >
          <div
            class="px-2 text-base font-medium rounded-lg"
            :class="docsPending(documents).length ? 'bg-yellow-9' : 'bg-green-9'"
          >
            {{ docsToSign(documents).length - docsPending(documents).length }}/{{ docsToSign(documents).length }}
            {{ $t('base.done') }}
          </div>
        </div>

        <div
          v-if="type === 'toSign' && docsPending(documents).length && !!request.deadline_at"
          class="font-medium text-base rounded-lg px-2 bg-primary-10 text-primary-3 flex mt-1.5"
        >
          {{ formattedDeadline }}
        </div>
      </div>


      <div
        class="mt-6 md:-mr-12"
        :class="documents.length > 2 ? 'grid grid-cols-2' : 'flex flex-col md:flex-row'"
      >
        <DocumentCard
          v-for="document in sortedDocuments"
          :key="document.id"
          :class="documents.length > 2 ? 'col-span-2 md:col-span-1' : 'md:mr-7'"
          :document="document"
          :recipient="request.recipient"
          :request-id="request.id"
          :type="type"
          :is-recipient-pending="
            !isRequestDeclined &&
              !!docsPending(documents) &&
              !!docsPending(documents).find(el => el.id === document.id)
          "
          :are-recipients-pending="
            !isRequestDeclined &&
              !!docsRecipientsPending(documents) &&
              !!docsRecipientsPending(documents).find(el => el.id === document.id)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import DocumentCard from "./DocumentCard.vue";
import useDocuments from "../../composables/Request/useDocuments";
import { formatRelativeTime } from "../../functions/helpers";

export default {
  components: {
    DocumentCard,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "toSign",
    },
    deadline: {
      type: String,
      default: undefined,
    },
    documents: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { docsPending, docsToSign, docsRecipientsPending, isRequestDeclined, isDocPending } = useDocuments(
      props.request.recipient.id, props.request
    );

    /* sort documents array (pendingDocs first) */
    const sortedDocuments = computed(() => {
      let tempDocuments = props.documents;
      return tempDocuments.sort((a, b) => {
        if (isDocPending(a) && !isDocPending(b)) {
          return -1;
        } else if (!isDocPending(a) && isDocPending(b)) {
          return 1;
        } else {
          return 0;
        }
      })
    });

    const formattedDeadline = computed(() => {
      return t("request.deadLine", {
        time: capitalizeFirstLetter(
          formatRelativeTime(props.request.deadline_at, 'de')
        ),
      });
    });

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return {
      t,
      isRequestDeclined,
      docsPending,
      docsToSign,
      docsRecipientsPending,
      formattedDeadline,
      sortedDocuments,
    };
  },
};
</script>
