<template>
  <SelectMobileAlternatives
    v-if="!mobileSignPending && !tmpBlob"
    @initMobileSign="arg => initMobileSign(arg)"
  />

  <div
    v-else
    class="text-center text-gray-800"
  >
    <ReviewUploadedInput
      v-if="tmpBlob"
      :blob="tmpBlob"
      @resetSignature="resetSignature"
      @rotateSignature="b => rotateSignature(b)"
    />

    <QRCodeMobileSignature
      v-if="mobileSignData"
      :qr-image="mobileSignData.image"
      :sms-request-id="mobileSignData.id"
      :mobile-view-opened="mobileViewOpened"
      @onCancel="cancelMobileSign"
    />
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import ReviewUploadedInput from "./ReviewUploadedInput";
import { useStore } from "vuex";
import SelectMobileAlternatives from "./InputMobile/SelectMobileAlternatives";
import QRCodeMobileSignature from "./InputMobile/QRCodeMobileSignature";
import useSignatureHelpers from "../../../composables/Document/useSignatureHelpers";
import setupEcho from "../../../functions/setupEcho";
import SignatureService from "../../../services/SignatureService";

export default {
  name: "InputMobile",
  components: {
    QRCodeMobileSignature,
    SelectMobileAlternatives,
    ReviewUploadedInput,
  },
  props: {
    defaultSignature: {
      type: Object,
      default: undefined
    },
  },
  emits: ['onValidationChanged'],
  setup(props, { emit }) {
    const store = useStore();
    const tmpBlob = ref(
      props.defaultSignature && props.defaultSignature.type === "mobile"
        ? props.defaultSignature.blob
        : ""
    );

    const { rotateImage } = useSignatureHelpers();

    const signatureUploaded = ref(false);
    const mobileViewOpened = ref(false);
    const mobileSignPending = ref(false);
    const mobileSignData = ref({});

    onMounted(() => {
      console.log(props.defaultSignature);
      if (props.defaultSignature && props.defaultSignature.type === "mobile") {
        console.log("SET ON MOUNT");
        tmpBlob.value = props.defaultSignature.blob;
        emit("onValidationChanged", true);
      }
    });

    onBeforeUnmount(() => {
      if (window.Echo) {
        window.Echo.leaveChannel(`mobilesign.${store.state.request.recipientId}`);
      }
    });

    function initMobileSign(type) {
      mobileSignPending.value = true;

      const onMobilSignInitiated = response => {
        mobileSignData.value = response.data;
        console.log(mobileSignData.value.temporary_url_for_testing);

        setupEcho(mobileSignData.value.id, store.state.auth.authToken);
        window.Echo.private(`mobilesign.${store.state.request.recipientId}`).listen(
          "MobileSigned",
          e => {
            console.log("MOBILE SIGNED");
            if (e.id.toString() === mobileSignData.value.id.toString()) {
              tmpBlob.value = e.signature;
              emit("onValidationChanged", true);
              signatureUploaded.value = true;
            }
          }
        ).listen(
          "MobileSignInitiated",
          e => {
            console.log("MOBILE Sign Initiated");
            if (e.id.toString() === mobileSignData.value.id.toString()) {
              mobileViewOpened.value = true;
            }
          }
        );
      };

      SignatureService.initMobileSign(
        store.state.request.requestId,
        store.state.request.recipientId,
        type,
        onMobilSignInitiated
      )
    }

    function getSignatureObj(callback) {
      const signatureObj = {
        type: "mobile",
        blob: tmpBlob.value,
      };

      callback(signatureObj);
    }

    function cancelMobileSign() {
      mobileSignData.value = null;
      mobileSignPending.value = false;
    }

    function resetSignature() {
      tmpBlob.value = "";
      mobileSignPending.value = false;
      signatureUploaded.value = false;
      emit("onValidationChanged", false);
    }

    function rotateSignature(clockwise = true) {
      rotateImage(tmpBlob.value, clockwise ? 90 : -90, resultBase64 => {
        tmpBlob.value = resultBase64;
      });
    }

    return {
      initMobileSign,
      mobileSignPending,
      signatureUploaded,
      mobileViewOpened,
      getSignatureObj,
      mobileSignData,
      cancelMobileSign,
      resetSignature,
      rotateSignature,
      tmpBlob,
    };
  },
};
</script>

<style scoped></style>
