<template>
  <form class="flex">
    <img
      :src="svgs.verificationPhone"
      class="hidden svg-verification xl:visible"
      width="220"
      alt="verification-phone"
    >
    <div class="w-full">
      <div class="relative flex flex-col w-full mb-3">
        <BaseLabel
          for="phonenumber"
          :label="t('base.phoneNumber')"
          class="mb-0.5"
        />
        <PhonenumberInput
          :class="{'has-error': phonenumberErrors.length}"
          id="phonenumber"
          @input="phonenumber = $event"
          :default-country-code="localization.country_code"
        />
        <ErrorMessages
          v-if="phonenumberErrors.length"
          :error-messages="phonenumberErrors"
        />
      </div>

      <div
        v-if="level === 'advanced'"
        class="relative flex flex-col w-full mb-5"
      >
        <FormInput
          id="pin"
          v-model="pin"
          type="password"
          :placeholder="t('base.pinPlaceholder')"
          :label="t('base.pin')"
          :error-messages="pinErrors"
          :label-info="t('base.pinInfo')"
        />
      </div>

      <BaseButton
        :on-click="onSubmit"
        type="submit"
        :loading="loading"
        :disabled="loading || !valid"
      >
        <span>
          {{ t("base.identify") }}
        </span>
      </BaseButton>
    </div>
  </form>
</template>

<script>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import useTheme from "../../composables/LayoutAndStyle/useTheme";
import svgs from "../../assets/svgs/index.js";
import FormInput from "../Form/FormInput";
import ErrorMessages from "../../components/Form/ErrorMessages";
import PhonenumberInput from "../../components/Verification/PhonenumberInput";
import useLocalization from "../../composables/useLocalization";
import {useStore} from "vuex";

export default {
  components: {
    FormInput,
    ErrorMessages,
    PhonenumberInput,
  },
  props: {
    level: {
      type: String,
      required: true
    }
  },
  emits: ["authenticate", "continueStep"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { theme } = useTheme();
    const loading = ref(false);
    const store = useStore();
    const { localization } = useLocalization();
    const pin = ref(null);
    const pinErrors = ref({});
    const phonenumber = ref(null);
    const phonenumberErrors = ref({});

    const valid = computed(() => {
      return (phonenumber.value && phonenumber.value.length > 0) &&
        ((pin.value && pin.value.length > 0) || props.level != "advanced");
    });

    const onSubmit = async () => {
      loading.value = true;

      try {
        await store.dispatch('auth/startMFA', {
          phoneNumber: phonenumber.value,
          pin: pin.value
        });
        return emit("continueStep", 2);
      } catch (error) {
        const { errors } = error.response.data;
        pinErrors.value = {};
        phonenumberErrors.value = {};

        if (errors.phonenumber) {
          phonenumberErrors.value = errors.phonenumber;
        }
        if (errors.access_pin) {
          pinErrors.value = errors.access_pin;
        }
      } finally {
        loading.value = false;
      }
    }

    return {
      t,
      svgs,
      theme,
      phonenumber,
      phonenumberErrors,
      pin,
      pinErrors,
      onSubmit,
      valid,
      loading,
      localization,
    };
  },
};
</script>

<style scoped>
.svg-verification {
  margin-left: -260px;
  margin-right: 40px;
  margin-top: -23px;
}
</style>
