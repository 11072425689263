import {htmlFieldId} from "../Document/useFieldLayout";
import scrollIntoView from "scroll-into-view";
import {getHtmlElementAbsoluteTopLeft} from "./useHtmlAbsolutePosition";
import useFocusedElement from "./useFocusedElement";


export default function useScrollToCurrentField(store, eventBus, panZoomHandler, isMobile) {

  let previousField = undefined;
  let currentField = undefined;
  let nextFieldModeActive = false;
  const focusedElement = useFocusedElement();

  /**
   * Used on desktop. Not used on mobile as we use pan there.
   * Based on @field given, scroll into this view with an top offset of 100px from @field
   * */
  function scrollToField(field) {
    const fieldHtmlId = htmlFieldId(field);
    const fieldHtml = document.getElementById(fieldHtmlId);
    scrollIntoView(fieldHtml, {
      align: {
        topOffset: 100,
      },
    });

    if (!previousField || previousField.id !== currentField.id || !focusedElement.activeElement) {
      eventBus.emit('focusCurrentField');
    }

  }


  function panToField(){
    let currentZoom = panZoomHandler.value.getCurrentZoom();

    const eleY = getHtmlElementAbsoluteTopLeft(htmlFieldId(currentField)).top;
    let y = (eleY + currentField.calculated.fieldHeight / 2 - window.innerHeight / currentZoom / 3);
    let x = (currentField.calculated.fieldX + currentField.calculated.fieldWidth / 2 - window.innerWidth / currentZoom / 2);

    if (y < 0) y = 0;
    if (x < 0) x = 0;
    if (x > (window.innerWidth / 2)) x = window.innerWidth / 2;
    if (currentZoom === 1) x = 0;

    if (!isMobile) {
      currentZoom = 1;
      x = 0;
    }

    panZoomHandler.value.moveZoomTo(-x * currentZoom, -y * currentZoom, 0);
    eventBus.emit('focusCurrentField');
  }

  /**
   * Find position of the next required field that the user has to fill out and
   * 1.) scroll to this position +
   * 2.) move the arrow to this position
   */
  function goToNextField() {
    const field = store.getters["documentEditor/currentTooltipField"];

    if (field) {
      previousField = currentField;
      currentField = field;

      eventBus.emit('showArrowHint', currentField);
      eventBus.emit('highlightNextField', currentField);

      //if (isMobile) {
      //  panToField(currentField);
      //} else {
        scrollToField(currentField);
      //}
    }
  }

  const onTabPressed = e => {
    if( e.which === 9 ) {
      if (nextFieldModeActive) {
        goToNextField();
      }
    }
  };

  const resumeNextFieldMode = () => {
    nextFieldModeActive = true;
    goToNextField();
  };
  const pauseNextFieldMode = () => {
    nextFieldModeActive = false;
    eventBus.emit('hideArrowHint');
  };

  const mountMe = () => {
    document.addEventListener('keyup', onTabPressed);
    eventBus.on('resumeNextFieldMode', resumeNextFieldMode);
    eventBus.on('pauseNextFieldMode', pauseNextFieldMode);
    focusedElement.mountMe();
  };

  const unmountMe = () => {
    document.removeEventListener('keyup', onTabPressed);
    eventBus.off('resumeNextFieldMode');
    eventBus.off('pauseNextFieldMode');
    focusedElement.unmountMe();
  };

  return {
    mountMe,
    unmountMe,
  }



}
