<template>
  <div class="flex items-center">
    <input
      :id="$attrs.id"
      type="checkbox"
      :checked="modelValue"
      class="absolute w-8 h-8 opacity-0"
      :class="{'cursor-not-allowed' : disabled}"
      :style="customInputStyle"
      :disabled="disabled"
      @change="$emit('update:modelValue', $event.target.checked)"
    >

    <div
      :style="$attrs.style"
      :class="$attrs.class"
      class="flex flex-shrink-0 justify-center items-center rounded-sm border-2 border-primary-4 focus-within:primary-4"
    >
      <BaseIcon
        :style="{height: $attrs.style.height}"
        class="hidden w-auto h-auto pointer-events-none fill-current p-0.5"
      >
        <svg
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            fill="none"
            fill-rule="evenodd"
          >
            <g
              transform="translate(-9 -11)"
              fill="currentColor"
              fill-rule="nonzero"
            >
              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>
      </BaseIcon>
    </div>
    <label
      v-if="label"
      :for="$attrs.id"
      class="select-none"
    >{{ label }}</label>
  </div>
</template>
<script>

export default {
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    customInputStyle: {
      type: String,
      required: false,
      default: ""
    }
  },
  emits: ["update:modelValue"],
}
</script>

<style scoped>
input:checked + div {
  @apply border-primary-4
}
input:checked + div svg {
  @apply block;
}
</style>
