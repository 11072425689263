<template>
  <div class="h-full flex flex-col">
    <CookieDataPrivacyAlert v-if="showCookieDataPrivacyAlert" />

    <Suspense>
      <DocumentsOverview
        v-if="isVerified && overviewMode"
        :request-id="requestId"
        :recipient-id="recipientId"
      />
      <Verification
        v-else-if="!isVerified"
        :request-id="requestId"
        :recipient-id="recipientId"
      />
      <InitialLoading v-else />
    </Suspense>

    <Footer />
  </div>
</template>

<script>
import {computed, watch} from "vue";
import {useI18n} from "vue-i18n";
import store from "@/store";
import DocumentsOverview from "./SigningAppPages/DocumentsOverview";
import Verification from "./SigningAppPages/VerificationMain";
import Cookies from "js-cookie";
import useDocument from "../composables/Document/useDocument";
import InitialLoading from "../components/DocumentOverview/InitialLoading";
import CookieDataPrivacyAlert from "../components/Legal/CookieDataPrivacyAlert";
import Footer from "../components/Legal/Footer";

/**
 * Fetch the request after the user has been verified.
 */
async function fetchRequestAndRedirect() {
  let request = await store.dispatch('request/fetchRequest');

  if (request.documents.length === 1) {
    const { showDocument } = useDocument(
      store.state.request.requestId,
      request.documents[0].id,
      store
    );

    showDocument();
  } else {
    store.commit('documents/setOverviewMode', true);
  }
}

export default {
  components: {
    InitialLoading,
    DocumentsOverview,
    Verification,
    CookieDataPrivacyAlert,
    Footer,
  },
  async beforeRouteEnter(to, from, next) {
    store.commit('request/setRequestId', to.params.requestId);
    store.commit('request/setRecipientId',  to.params.recipientId);

    let hash = window.location.hash.substring(1);
    if (!hash) {
      hash = Cookies.get(`hash_${to.params.requestId}`);
    }

    store.dispatch('auth/setAndPersistHash', hash);
    store.commit("SET_LOADING", true);

    const isVerified = () =>
      store.getters['auth/isVerified'];

    const isSignteqVerified = () =>
      store.getters['auth/isSignteqVerified'];


    if (isSignteqVerified()) {
      /**
       * Triggered if recipient has already been verified, e.g. on go back from DocumentEditor
       */
      store.commit('documents/setOverviewMode', true)
    }

    if (!isSignteqVerified()) {
      /*
       * Initial Request is called only if recipient is not verified yet.
       */
      await store.dispatch("initiate");

      if (isSignteqVerified()) {
        console.log("Post initiate issignteqverified true", isVerified(), isSignteqVerified());

        await store.dispatch("auth/authenticateBasic");

        if (isVerified()) {
          let request = store.state.request.request;
          if (request === undefined) {
            await fetchRequestAndRedirect()
          }
        }
      }
    }

    console.log("BEOFRE ROUTE ENTER");

    store.commit("SET_LOADING", false);
    next();
  },

  props: {
    requestId: {
      type: String,
      required: true
    },
    recipientId: {
      type: String,
      required: true
    }
  },
  setup() {
    const { t } = useI18n();

    const error = computed(() => {
      return store.state.error;
    });
    const overviewMode = computed(() => {
      return store.state.documents.overviewMode;
    });
    const isVerified = computed(() => {
      return store.getters['auth/isVerified'];
    });
    const showCookieDataPrivacyAlert = computed(() => {
      return isVerified.value && overviewMode.value || !isVerified.value;
    });

    watch(isVerified, async (newVal, oldVal) => {
      if (!oldVal && newVal) {
        /**
         * delayed verification --> no "basic" security level
         */
        await fetchRequestAndRedirect();
      }
    });

    return { t, error, isVerified, overviewMode, fetchRequestAndRedirect, showCookieDataPrivacyAlert };
  },
};
</script>
