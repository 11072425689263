<template>
  <PopOverTransition>
    <div
      v-if="isVisible"
      class="absolute z-50 w-full cursor-default -top-8 -left-3 ml-2 mb-3 md:w-80"
    >
      <div
        class="flex flex-col px-4 pt-3 pb-5 rounded-md shadow-lg text-neutral-1 bg-neutral-9"
      >
        <div class="flex flex-col space-y-3">
          <div v-if="recipientsPending.length">
            <span class="text-base font-medium text-neutral-1">
              {{ startLowerCase(t("request.pendingSignatures")) }}:
            </span>
            <div class="flex pt-1">
              <div
                v-for="recipient in recipientsPending"
                :key="recipient.id"
              >
                <RecipientBadge
                  class="badge"
                  :is-pending="true"
                  :initials="recipient.initials"
                  :name="recipient.name"
                />
              </div>
            </div>
          </div>

          <div v-if="recipientsCompleted.length">
            <span class="text-base font-medium text-neutral-1">
              {{ startLowerCase(t("request.completedSignatures")) }}:
            </span>
            <div class="flex pt-1">
              <div
                v-for="recipient in recipientsCompleted"
                :key="recipient.id"
              >
                <RecipientBadge
                  :is-pending="false"
                  :initials="recipient.initials"
                  :name="recipient.name"
                />
              </div>
            </div>
          </div>

          <div v-if="recipientsCC.length">
            <span class="text-base font-medium text-neutral-1">
              {{ t("request.toView") }}:
            </span>
            <div class="flex pt-1">
              <div
                v-for="recipient in recipientsCC"
                :key="recipient.id"
              >
                <RecipientBadge
                  :initials="recipient.initials"
                  :name="recipient.name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PopOverTransition>
</template>

<script>
import { getCurrentInstance, watch, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { startLowerCase } from "../../functions/helpers";
import RecipientBadge from "./RecipientBadge";
import PopOverTransition from "../Common/Transitions/PopOverTransition";

export default {
  components: {
    PopOverTransition,
    RecipientBadge,
  },
  props: {
    showRecipientsInfo: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const internalInstance = getCurrentInstance();
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const { t } = useI18n();
    const isVisible = ref(props.showRecipientsInfo);

    watch(
      () => props.showRecipientsInfo,
      () => {
        isVisible.value = props.showRecipientsInfo;
      }
    );

    emitter.on("close-recipients-infos", () => {
      isVisible.value = false;
    });

    const recipientsPending = computed(() => {
      return props.document.recipients.filter(
        recipient =>
          recipient.type === "signatory" && recipient.status === "pending"
      );
    });

    const recipientsCompleted = computed(() => {
      return props.document.recipients.filter(
        recipient =>
          recipient.type === "signatory" && recipient.status === "completed"
      );
    });

    const recipientsCC = computed(() => {
      return props.document.recipients.filter(
        recipient => recipient.type === "recipient" || recipient.type === "copy"
      );
    });

    return {
      t,
      isVisible,
      recipientsPending,
      recipientsCompleted,
      recipientsCC,
      startLowerCase,
    };
  },
};
</script>

