<template>
  <div
    class="select-none"
    :title="name"
  >
    <!-- own Status -->
    <div class="relative flex items-center justify-center">
      <BaseIcon
        class="z-0 text-neutral-8"
        :size="small ? '30' : '40'"
        view-box="0 0 16 16"
        :color="colors.background"
      >
        <RecipientIcon />
      </BaseIcon>
      <span
        v-if="initials && !showIcon"
        :style="`color:${colors.text}`"
        class="absolute z-0 text-base font-black text-neutral-3"
      >
        {{ initials }}
      </span>
      <BaseIcon
        v-if="showIcon"
        :color="colors.text"
        class="absolute z-10"
      >
        <ExclamationIcon v-if="isPending" />
        <CheckIcon v-else />
      </BaseIcon>
    </div>
  </div>
</template>

<script>
import RecipientIcon from "../icons/IconRecipient";
import useTailwindTheme from "../../composables/LayoutAndStyle/useTailwindTheme";
import CheckIcon from "../icons/IconCheck";
import ExclamationIcon from "../icons/IconExclamation";

export default {
  components: {
    CheckIcon,
    ExclamationIcon,
    RecipientIcon,
  },
  props: {
    isPending: {
      type: Boolean,
      default: null,
      required: false,
    },
    initials: {
      type: String,
      default: null,
      required: false,
    },
    name: {
      type: String,
      default: null,
      required: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {
    const { getPendingStateColors } = useTailwindTheme();
    const colors = getPendingStateColors(props.isPending);

    return {
      colors,
    };
  },
};
</script>
