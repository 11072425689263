<template>
  <HeaderMessage
    :text="error.message"
    class="mt-36 mx-3"
    message-only
  >
    <span>{{ $t("base.contactSupportText") }}</span>
    <a
      class="_link"
      href="http://www.signteq.io/kontakt"
      target="_blank"
    >
      Support
    </a>
    .
  </HeaderMessage>
</template>

<script>
import HeaderMessage from "./HeaderMessage.vue";

export default {
  components: {
    HeaderMessage,
  },
  props: {
    error: {
      type: Object,
      required: true,
      default: () => {},
    }
  },
};
</script>

<style></style>
