<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
  >
    <path
      d="M8,12.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C9.1,11.9,8.6,12.4,8,12.4z M8.7,8.1
	C8.6,9,7.4,9,7.3,8.1L6.9,4.4c0-0.4,0.3-0.8,0.7-0.8c0,0,0.1,0,0.1,0h0.7c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0.1,0,0.1L8.7,8.1z"
    />
  </svg>
</template>

<script>
export default {
  name: "ImportantIcon",
};
</script>
