import Toastify from "@/functions/toastify";
import tailwindConfig from "../../tailwind.config";

const fullConfig = tailwindConfig;
const warningBgColor = fullConfig.theme.colors.yellow["8"];
const warningColor = fullConfig.theme.colors.yellow["3"];
const successColor = fullConfig.theme.colors.green["3"];
const successBgColor = fullConfig.theme.colors.green["8"];

const notification = Toastify({
  duration: 3000,
  newWindow: true,
  close: false,
  gravity: "top", // `top` or `bottom`
  position: "right", // `left`, `center` or `right`
  stopOnFocus: true, // Prevents dismissing of toast on hover
  onClick: function () {
    notification.hideToast();
  },
});

export const showNotification = payload => {
  notification.options.text = payload.message;
  notification.options.avatar = payload.icon;
  notification.options.color = successColor;
  notification.options.backgroundColor = successBgColor;
  notification.options.className = "font-medium";

  if (payload.type && payload.type === "warning") {
    notification.options.color = warningColor;
    notification.options.backgroundColor = warningBgColor;
  }
  notification.showToast();
};

export const hideNotification = () => {
  notification.hideToast();
};
