<template>
  <div class="absolute top-0 left-0 right-0 hidden lg:block">
    <div
      id="arrowHint"
      :style="arrowStyling"
      class="absolute z-20 h-10 duration-500 ease-out transform -translate-x-full"
    >
      <div v-show="show && fieldsLeft">
        <BaseIcon
          view-box="0 0 224 384"
          class="absolute top-0 h-9 transform right-0 -mr-5"
        >
          <IconArrowHint :color="colors.yellow[8]" />
        </BaseIcon>

        <div
          class="flex items-center h-9 px-2.5 text-base font-medium bg-yellow-8 text-yellow-2 rounded-l-md"
        >
          {{ arrowHintText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import eventBus from "../../eventbus";
import useTailwindTheme from "../../composables/LayoutAndStyle/useTailwindTheme";
import { htmlFieldId } from "../../composables/Document/useFieldLayout";
import IconArrowHint from "../icons/IconArrowHint";
import {getHtmlElementAbsoluteTopLeft} from "../../composables/LayoutAndStyle/useHtmlAbsolutePosition";

export default {
  name: "ArrowHint",
  components: { IconArrowHint },
  setup() {
    const show = ref(false);
    const store = useStore();
    const { t } = useI18n();
    const { colors } = useTailwindTheme();
    const arrowTop = ref(0);
    const fieldsLeft = computed(() => {
      return store.getters["documentEditor/fieldsToSignLeft"].length > 0;
    });

    let currentField = ref(undefined);
    const currentTooltipField = ref({});

    /**
     * Based on @field given, recalculate and set the arrow's new position
     * */
    function recalcArrowPosition(field) {
      const fieldHtmlId = htmlFieldId(field);

      if (field.calculated) {
        const { top: targetTop, height: targetHeight } = getHtmlElementAbsoluteTopLeft(
          fieldHtmlId
        );

        const arrowHint = document.getElementById("arrowHint");
        const arrowHintHeight = arrowHint.clientHeight;

        arrowTop.value = targetTop + targetHeight / 2 - arrowHintHeight / 2;
      }
    }

    const arrowHintText = computed(() => {
      switch (currentTooltipField.value.type) {
        case "checkbox-field": return t('document.arrowHintCheckBoxField');
        case "signature-field": return t('document.arrowHintSignatureField');
        default: return t('document.arrowHintTextField');
      }
    });

    window.addEventListener("resize", () => {
      recalcArrowPosition(currentField);
    });

    eventBus.on("showArrowHint", (currentField) => {
      currentTooltipField.value = currentField;
      recalcArrowPosition(currentField);
      show.value = true;
    });

    eventBus.on("hideArrowHint", () => {
      show.value = false;
    });

    const arrowStyling = computed(() => {
      if (!fieldsLeft.value) {
        return {
          top: "-90px",
          right: "-156px",
        };
      }

      // 72: Menu height
      return {
        top: `${arrowTop.value - 56}px`, // 56px = Menu Height
      };
    });

    return {
      show,
      fieldsLeft,
      arrowStyling,
      colors,
      arrowHintText,
    };
  },
};
</script>
