<template>
  <div class="fixed top-0 left-0 z-0 w-full h-full">
    <div
      v-for="index in 8"
      :key="index"
      class="_symbols"
    >
      <img
        src="../../assets/svgs/background/symbol2.svg"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { gsap } from "gsap";
export default {
  setup() {
    onMounted(() => {
      const cans = gsap.utils.toArray("._symbols > img");
      cans.forEach(can => {
        gsap.set(can, {
          x: Math.random() * 1500,
          y: Math.random() * 1500,
          rotation: randomAngle(-1),
        });

        moveX(can, Math.random() * 150);
        moveY(can, Math.random() * 150);
        rotate(can, Math.random() * 100);
        // moveX(can, 1);
        // moveY(can, -1);
        // rotate(can, 1);
      });
    });

    const randomX = random(1, 10);
    const randomY = random(1, 10);
    const randomDelay = random(0, 1);
    const randomTime = random(80, 120);
    const randomAngle = random(-10, 10);

    function rotate(target, direction) {
      gsap.to(target, randomTime(), {
        rotation: randomAngle(direction),
        delay: randomDelay(),
        // ease: Sine.easeInOut,
        onComplete: rotate,
        onCompleteParams: [target, direction],
      });
    }

    function moveX(target, direction) {
      gsap.to(target, randomTime(), {
        x: randomX(direction),
        // ease: Sine.easeInOut,
        onComplete: moveX,
        onCompleteParams: [target, direction],
      });
    }

    function moveY(target, direction) {
      gsap.to(target, randomTime(), {
        y: randomY(direction),
        // ease: Sine.easeInOut,
        onComplete: moveY,
        onCompleteParams: [target, direction],
      });
    }

    function random(min, max) {
      const delta = max - min;
      return (direction = 1) => (min + delta * Math.random()) * direction;
    }
  },
};
</script>

<style>
._symbols > img {
  width: 10px;
  height: 10px;
}
</style>
