import { computed } from "vue";
import { useStore } from "vuex";

export default function useTheme() {
  const store = useStore();
  const theme = computed(() => {
    return store.state.request.branding;
  });

  return {
    theme,
  };
}
