<template>
  <div>
    <SignPadContainer>
      <p
        class="absolute bottom-0 left-0 mb-12 ml-4 font-medium text-4xl"
        :class="`typefont_${selectedFontType}`"
        v-text="signatureText"
      />
    </SignPadContainer>

    <div class="flex justify-between items-center mt-2">
      <div class="flex justify-between items-center flex-1">
        <BaseInput
          v-model="signatureText"
          class="px-1 md:max-w-sm mr-4 md:mr-0"
        />
      </div>
    
      <!-- Language Selector DropDown -->
      <DropDownMenuButton
        :text="$t('signature.typos')"
        class="inline-flex"
      >
        <DropDownMenuButtonItem
          v-for="font in availableTypefonts"
          :key="font"
          class="text-2xl"
          :class="'typefont_' + font"
          @onClick="selectedFontType = font"
        >
          <template #default>
            {{ signatureText }}
          </template>
        </DropDownMenuButtonItem>
      </DropDownMenuButton>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { useStore } from 'vuex';
import SignatureService from "../../../services/SignatureService";
import useSignatureHelpers from "../../../composables/Document/useSignatureHelpers";
import useSignatureFonts from "../../../composables/LayoutAndStyle/useSignatureFonts";
import SignPadContainer from "./SignPadContainer";
import DropDownMenuButton from '../../Common/Navigation/DropDownMenuButton';
import DropDownMenuButtonItem from '../../Common/Navigation/DropDownMenuButtonItem';
export default {
  name: "InputType",
  components: {
    SignPadContainer,
    DropDownMenuButton,
    DropDownMenuButtonItem,
  },
  props: {
    defaultSignature: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  emits: ["onValidationChanged"],
  setup(props, { emit }) {
    const store = useStore();
    let request = store.state.request.request;
    const { rawFonts } = useSignatureFonts();
    const availableTypefonts = ref(rawFonts.map(el => el.class));
    onMounted(() => {
      // initially enable submit since input is pre-filled
      emit("onValidationChanged", true);
    });

    const selectedFontType = ref("dawning_of_a_new_day");
    const signatureText = ref(request.recipient.name);
    const tmpBlob = ref("");
    const {
      blobString,
      getBlobFromBlobString,
      createSignatureImageFromBlob,
    } = useSignatureHelpers();

    if (props.defaultSignature && props.defaultSignature.type === "type") {
      // eslint-disable-next-line vue/no-setup-props-destructure
      signatureText.value = props.defaultSignature.text;
      // eslint-disable-next-line vue/no-setup-props-destructure
      selectedFontType.value = props.defaultSignature.typefont;
    }

    emit("onValidationChanged", signatureText.value.length > 0);
    watch(signatureText, () => {
      emit("onValidationChanged", signatureText.value.length > 0);
    });

    function getSignatureObj(callback) {
      const apiObj = {
        type: "text",
        text: signatureText.value,
        font: selectedFontType.value,
      };

      SignatureService.signatureMedia(apiObj).then(response => {
        tmpBlob.value = getBlobFromBlobString(blobString(response.data.blob));
        const signatureObj = {
          type: "type",
          blob: tmpBlob.value,
          text: signatureText.value,
          typefont: selectedFontType.value,
          image: createSignatureImageFromBlob(tmpBlob.value),
        };
        callback(signatureObj);
      });
    }

    return {
      getSignatureObj,
      selectedFontType,
      signatureText,
      availableTypefonts,
    };
  },
};
</script>

<style scoped>
@import "../../../assets/styles/signature-fonts.css";
</style>
