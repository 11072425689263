<template>
  <div class="overflow-hidden relative">
    <div
      ref="signPadContainerRef"
      class="relative w-full h-40 bg-blue-100 rounded-md border md:border-2 select-none md:h-48 border-primary-4 bg-primary-10"
      style="padding-top: 33%"
    />

    <slot />
  </div>
  <div class="flex justify-between items-start">
    <p
      class="pt-2 text-base font-medium text-neutral-4"
    >
      {{ $t("signature.yourSignature") }}
    </p>
    <div class="flex items-center pt-2">
      <BaseButton
        v-if="showRotate"
        small
        secondary
        class="mr-2 outline-none"
        style="transform: rotateY(180deg)"
        @click="$emit('rotateSignature', false)"
      >
        <BaseIcon size="20">
          <IconRotate />
        </BaseIcon>
      </BaseButton>

      <BaseButton
        v-if="showRotate"
        secondary
        small
        class="mr-2 outline-none"
        @click="$emit('rotateSignature', true)"
      >
        <BaseIcon size="20">
          <IconRotate />
        </BaseIcon>
      </BaseButton>

      <BaseButton
        v-if="showDelete"
        :disabled="!activeDelete"
        small
        secondary
        class="z-50"
        @click="$emit('deleteSignature')"
      >
        <BaseIcon size="20">
          <IconTrash />
        </BaseIcon>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import IconRotate from "../../icons/IconRotate";
import IconTrash from "../../icons/IconTrash";
export default {
  name: "SignPadContainer",
  components: {
    IconRotate,
    IconTrash,
  },
  props: {
    activeDelete: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showRotate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["rotateSignature", "deleteSignature"],
  setup(props) {
    const signPadContainerRef = ref(null);

    return {
      signPadContainerRef,
    };
  },
};
</script>
