import { computed, ref } from "vue";

/**
 * Calculates the current logical stadium of the @field.
 */
export default function useFieldStadium(field, store) {
  const fieldStadium = {};

  const isReadOnly = computed(() => {
    return store.getters["documents/isReadOnlyMode"] || field.read_only;
  });

  // General
  fieldStadium.todoOptional = computed(() => {
    return !isReadOnly.value && !field.required && isFieldOpen(field);
  });
  fieldStadium.todoRequired = computed(() => {
    return !isReadOnly.value && field.required && isFieldOpen(field);
  });
  fieldStadium.done = computed(() => {
    return !isReadOnly.value && !isFieldOpen(field);
  });

  // Does this field belong to me or to others
  fieldStadium.isMine = computed(() => {
    return field.recipient_id === store.state.request.recipientId;
  });
  fieldStadium.isForeign = computed(() => {
    return field.recipient_id !== store.state.request.recipientId;
  });

  fieldStadium.mine = {};
  fieldStadium.mine.todoOptional = computed(() => {
    return fieldStadium.todoOptional.value && fieldStadium.isMine.value;
  });
  fieldStadium.mine.todoRequired = computed(() => {
    return fieldStadium.todoRequired.value && fieldStadium.isMine.value;
  });
  fieldStadium.mine.done = computed(() => {
    return fieldStadium.done.value && fieldStadium.isMine.value;
  });

  fieldStadium.foreign = {};
  fieldStadium.foreign.todoOptional = computed(() => {
    return fieldStadium.todoOptional.value && fieldStadium.isForeign.value;
  });
  fieldStadium.foreign.todoRequired = computed(() => {
    return fieldStadium.todoRequired.value && fieldStadium.isForeign.value;
  });
  fieldStadium.foreign.done = computed(() => {
    return fieldStadium.done.value && fieldStadium.isForeign.value;
  });

  fieldStadium.disabled = computed(() => {
    return (
      !isReadOnly.value &&
      (field.disabled || field.recipient_id !== store.state.request.recipientId)
    );
  });
  fieldStadium.doneOthers = fieldStadium.disabled;
  fieldStadium.readOnly = isReadOnly;

  fieldStadium.meta = {};
  fieldStadium.meta.highlightedNext = computed(() => {
    const currentField = store.getters["documentEditor/currentTooltipField"];
    return !isReadOnly.value && currentField && currentField.id === field.id;
  });

  fieldStadium.meta.highlightedPrevious = computed(() => {
    const previousField = store.getters["documentEditor/previousTooltipField"];
    return !isReadOnly.value && previousField && previousField.id === field.id;
  });

  /* disable styling of date-field */
  /* is always disabled for now and gets custom styling */
  fieldStadium.meta.showInputBackground = field.type !== "date-field";

  return ref(fieldStadium);
}

/**
 * Static functions
 */

export const isFieldFillable = (field, recipientId) => {
  return (
    !field.disabled &&
    (recipientId === undefined || field.recipient_id === recipientId) &&
    field.recipient_id !== "00000000-0000-0000-0000-000000000000" &&
    field.type !== "date-field"
  )
};

export const isFieldRequired = (field, recipientId) => {
  return field.required && isFieldFillable(field, recipientId)
};

export const isFieldOpen = (field, recipientId = undefined) => {
  return (
    isFieldFillable(field, recipientId) && !field.signature && !field.value
  );
};

export const isFieldForRecipient = (field, recipientId) => {
  return field.recipient_id === recipientId;
};
