<template>
  <div
    :value="modelValue"
    class="flex w-full space-x-2 md:w-20"
  >
    <input
      v-for="(n, i) in 6"
      :key="n"
      :ref="
        el => {
          if (el) otpPartsEl[i] = el;
        }
      "
      v-model="otpParts[n - 1]"
      required
      type="text"
      pattern="[0-9]*"
      inputmode="numeric"
      maxlength="1"
      placeholder="*"
      class="block w-10 h-10 p-2 font-bold text-center border-2 rounded-md appearance-none max-w-5 border-neutral-9 radius-6 placeholder-neutral-7 focus:outline-none focus:border-primary-6"
      @paste="onPaste"
      @click="onClick(n, $event)"
      @input="onInput(n, $event)"
      @focusin="onFocus(n, $event)"
      @keyup="onCodeKeyup(n, $event)"
      @keydown="onCodeKeydown(n, $event)"
    >
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick } from "vue";
export default {
  props: {
    modelValue: {
      type: [String],
      default: "",
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const otpPartsEl = ref([]);
    const otpParts = ref([]);
    const leftShiftPressed = ref(false);

    const otp = computed(() => {
      return otpParts.value.join("");
    });

    onMounted(() => {
      nextTick(() => {
        otpPartsEl.value[0].focus();
      });
    });

    function onPaste(event) {
      const clipboardData =
        event.clipboardData ||
        event.originalEvent.clipboardData ||
        window.clipboardData;
      const pastedData = clipboardData.getData("text");

      if (!pastedData) {
        return;
      }

      pastedData
        .slice(0, 6)
        .split("")
        .forEach((character, index) => {
          otpParts.value[index] = character;
        });

      setTimeout(() => {
        otpPartsEl.value[5].focus();
      }, 50);
    }

    function onInput(index, event) {
      if (event.target.value) {
        otpParts.value[index - 1] = event.target.value.replace(/[^0-9]/g, "");
      }
      emit("update:modelValue", otp.value);
    }

    function onFocus(index, event) {
      if (event.target.value) {
        otpPartsEl.value[index - 1].select();
      }
    }

    function onClick(index, event) {
      if (event.target.value) {
        otpPartsEl.value[index - 1].select();
      }
    }

    function onCodeKeyup(index, event) {
      // we dont jump to the next field, as the user cleared the field
      if (["Backspace", "Delete"].includes(event.key)) {
        return;
      }

      // if the previous key event was shift left, no jump
      if (event.key === "Shift" && leftShiftPressed.value) {
        return;
      }

      // the user goes back, we set the flag
      if (event.key === "Tab" && event.shiftKey) {
        leftShiftPressed.value = true;
        return;
      }

      // dont jump to the next field on native browser tab
      if (event.key === "Tab") {
        return;
      }

      if (event.key === "ArrowLeft") {
        return;
      }

      if (index < 6) {
        otpPartsEl.value[index].focus();
      }
    }

    function onCodeKeydown(index, event) {
      if (!["Backspace", "Delete", "ArrowLeft"].includes(event.key)) {
        return;
      }

      if (event.key === "ArrowLeft" && index > 1) {
        return setTimeout(() => {
          otpPartsEl.value[index - 2].focus();
          otpPartsEl.value[index - 2].select();
        }, 50);
      }

      if (event.target.value === "" && index > 1) {
        setTimeout(() => {
          otpPartsEl.value[index - 2].focus();
        }, 50);
      }
    }
    return {
      otpParts,
      otpPartsEl,
      onPaste,
      onFocus,
      onInput,
      onClick,
      onCodeKeyup,
      onCodeKeydown,
    };
  },
};
</script>
