<template>
  <div>
    <div class="block mt-4 mb-3 text-base font-medium rtl:text-right">
      {{ $t("signature.mobile.howTo2") }}
      <div class="text-sm font-normal text-gray-700">
        {{ $t("signature.mobile.buttonExplanation") }}
      </div>
    </div>

    <div class="flex-col items-center space-y-2 md:flex-row">
      <span class="mr-4">
        <BaseButton
          small
          secondary
          icon="fal fa-camera"
          @click="() => $emit('initMobileSign', 'upload')"
        >
          {{ $t("signature.mobile.useSmartphoneCamera") }}
        </BaseButton>
      </span>

      <BaseButton
        small
        secondary
        icon="fal fa-signature"
        :on-click="() => $emit('initMobileSign', 'draw')"
      >
        {{ $t("signature.mobile.drawOnTablet") }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectMobileAlternatives",
  emits: ["initMobileSign"],
};
</script>

<style scoped></style>
