<template>
  <div class="flex relative z-10 justify-between mb-4">
    <ul class="relative pb-4 cursor-default select-none text-neutral-7">
      <li class="flex items-center space-x-3">
        <BaseIcon
          :color="theme.primaryBgColor"
          view-box="0 0 32 32"
        >
          <BulletListItemDisabled v-if="completedVerification" />
          <BulletListItem v-else />
        </BaseIcon>
        <span
          class="text-base md:text-lg"
          :class="completedVerification ? 'text-neutral-7' : 'text-neutral-1 font-medium'"
        >
          {{ t("base.verify") }}
        </span>
      </li>
      <li class="flex items-center mt-2 space-x-3">
        <BaseIcon
          :color="theme.primaryBgColor"
          view-box="0 0 32 32"
        >
          <BulletListItem v-if="completedVerification" />
          <BulletListItemDisabled v-else />
        </BaseIcon>
        <span
          class="text-base md:text-lg"
          :class="completedVerification ? 'text-neutral-1 font-medium' : 'text-neutral-7'"
        >
          {{ t("base.sign") }}
        </span>
      </li>
    </ul>
    <!-- TODO: discuss if logo branding is important on mobile and how to handle it -->
    <!--    <div class="flex items-center pb-4">
      <img
        v-if="isSmallScreen"
        :alt="`${theme.name} logo'`"
        :src="theme.logoLight"
        class="flex w-14 h-auto md:w-18"
      >
    </div>-->
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import useTheme from "../../composables/LayoutAndStyle/useTheme";

import BulletListItem from "../icons/IconBulletListItem";
import BulletListItemDisabled from "../icons/IconBulletListItemDisabled";

export default {
  components: {
    BulletListItem,
    BulletListItemDisabled,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { theme } = useTheme();
    const completedVerification = computed(() => {
      return store.state.auth.completedVerification;
    });
    return {
      t,
      theme,
      completedVerification,
    };
  },
};
</script>
