import useBreakpoints from "vue-next-breakpoints";
import useTailwindTheme from "./useTailwindTheme";
import { computed } from "vue";

export default function useBreakPoints() {
  const { config } = useTailwindTheme();
  const breakpoints = useBreakpoints({
    sm: config.screens.sm,
    md: config.screens.md,
    lg: config.screens.lg,
    xl: config.screens.xl,
    xxl: config.screens['2xl'],
  });

  const isSmallScreen = computed(() => {
    return breakpoints.sm.matches || breakpoints.md.matches;
  });

  return {
    breakpoints,
    isSmallScreen,
  };
}
