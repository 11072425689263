<template>
  <modal
    custom-class="bg-purple text-neutral-10 pb-6 px-3"
    @onCancel="onCancel"
  >
    <template #description>
      <div class="text-center text-white">
        <div class="flex items-center justify-center">
          <span class="text-2xl font-medium md:text-4xl">
            {{ $t('request.allDoneModal.title') }}
          </span>
          <img
            class="w-12 mb-4 ml-6 md:w-16"
            :src="svgs.confetti"
            alt="confetti"
          >
        </div>

        <div class="mb-1 text-base md:text-xl">
          {{ $t('request.allDoneModal.info') }}
        </div>
        <div class="mb-6 text-sm md:text-base">
          {{ $t('request.allDoneModal.free14DaysTest') }}
        </div>
        
        <div class="mb-4">
          <BaseInput
            v-model="email"
            type="email"
            :placeholder="$t('base.email')"
            class="px-2 py-1 font-medium bg-transparent placeholder-neutral-9 max-w-sm"
          />
        </div>
        
        <div class="flex items-center justify-center">
          <BaseButton
            small
            text-link-white
            @click="onCancel"
          >
            {{ $t('request.allDoneModal.maybeLater') }}
          </BaseButton>
          <BaseButton
            secondary
            small
            class="ml-2"
            @click="onSubmit"
          >
            {{ $t('request.allDoneModal.testNow') }}
          </BaseButton>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import Cookies from "js-cookie";
import OthersService from "../../services/OthersService";
import Modal from "../Common/Modal";
import svgs from "../../assets/svgs";

export default {
  name: "AllDoneModal",
  components: {
    Modal
  },
  emits: ["onClose", "onSubmit"],
  setup(_, { emit }) {
    const store = useStore();
    const email = ref(null);

    function onCancel() {
      Cookies.set('all_done_popup_shown', true, { expires: 365 });
      emit("onClose");
    }

    async function onSubmit() {
      await OthersService.submitTestAccount(email.value, store.state.request.requestId);
      Cookies.set('all_done_popup_shown', true, { expires: 365 });
      emit('onClose');
    }

    return {
      onCancel,
      onSubmit,
      email,
      svgs,
    }
  }
};
</script>
