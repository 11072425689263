import { createI18n } from "vue-i18n";
import locales from "../locales/index";

const i18n = createI18n({
  legacy: false,
  locale: "de",
  globalInjection: true,
  fallbackLocale: "en",
  messages: locales,
});

export default i18n;
