<template>
  <div>
    <div>
      <div v-bind="getRootProps()">
        <input
          ref="inputRef"
          v-bind="getInputProps()"
        >
        <div
          v-if="!isLoading"
          class="p-16 text-center border-2 border-dashed rounded-md cursor-pointer w-25 border-primary-4 bg-primary-10"
        >
          <span v-if="isDragActive">
            {{ $t("signature.upload.dropFilesHere") }}.
          </span>
          <span v-else>
            {{ isMobileMode ? $t("signature.upload.captureOrSelectFilesMobile") : $t("signature.upload.captureOrSelectFiles") }}.
          </span>
          <div class="text-xs">
            {{ $t("signature.mobile.supportedFileTypes") }}
          </div>
        </div>
        <div
          v-if="isLoading"
          class="p-16 w-100 border-dashed border-2 border-primary-4 rounded-default bg-primary-10 text-center cursor-pointer"
        >
          <Loader :text="$t('signature.upload.loadingImage')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import { useDropzone } from "vue3-dropzone";
import Loader from "../../components/Common/Loader";

export default {
  name: "Dropzone",
  components: {
    Loader,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['uploaded'],
  setup(_, { emit }) {
    const inputRef = ref(null);
    const isMobileMode = inject('mobileMode');

    function onDrop(acceptFiles) {
      if (acceptFiles.length > 0) {
        const file = acceptFiles[0];

        if (!file.type.startsWith("image/")) {
          return console.error("User uploaded a file that is not an image.");
        }

        emit("uploaded", acceptFiles[0]);
      }
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      inputRef,
      isMobileMode,
      ...rest,
    };
  },
};
</script>
