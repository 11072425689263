import axios from "axios";
import store from "@/store";
import eventBus from "@/eventbus";
import i18n from "@/i18n";
import svgs from "@/assets/svgs/index.js";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  timeout: 15000,
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    console.log("#error response", error.response);

    if (error.response) {
      if (error.response.status === 422 || error.response.status === 403) {
        /*
         * reject promise to handle response right next to the request call
         * e.g. validation error
         */
        return Promise.reject(error);
      } else if (error.response.status === 404) {
        /*
         * if error is 404 show error message that request is not to be found
         */
        store.commit("SET_ERROR", {
          message: i18n.global.t("base.requestNotFound"),
        });
      } else {
        store.commit("SET_ERROR", error.response.data);
      }
    } else {
      /**
       * network connection error
       * if request is already initiated show only notification message
       * instead of showing error message
      */
      if (store.state.level) {
        eventBus.emit("show-notification", {
          type: "warning",
          message: i18n.global.t("notification.networkError"),
          icon: svgs.wifiOff,
        });
      } else {
        store.commit("SET_ERROR", {
          message: i18n.global.t("base.noServerConnection"),
        });
      }
    }
  }
);

export default apiClient;
