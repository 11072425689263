<template>
  <FadeTransition>
    <div
      class="flex fixed right-0 bottom-0 left-0 z-10 justify-center items-center h-14 bg-transparent shadow-top md:shadow md:top-0"
    >
      <div class="flex relative px-4 w-full h-full md:px-8">
        <div class="absolute top-0 right-0 bottom-0 left-0 bg-white" />

        <div class="relative mx-auto flex justify-between w-full md:max-w-[800px]">
          <div class="flex flex-1 justify-between items-center">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import FadeTransition from "../../components/Common/Transitions/FadeTransition";
// import {onMounted, onUnmounted, ref} from "vue";
// import useFocusedElement from "../../composables/LayoutAndStyle/useFocusedElement";
// import useDeviceDetection from "../../composables/LayoutAndStyle/useDeviceDetection";
// import useVirtualKeyboardDetector from "../../composables/LayoutAndStyle/useVirtualKeyboardDetector";
// import eventBus from "../../eventbus";

export default {
  components: {
    FadeTransition,
  }
}
  /*TODO: check why this was implemented*/
  /*
  setup() {
    const isVisible = ref(true);
    const { activeElement, unmountMe, mountMe } = useFocusedElement();
    onMounted(() => mountMe());
    onUnmounted(() => unmountMe());
    eventBus.on("showToolbar", (showToolbar) => {
      isVisible.value = showToolbar;
    });
    const { isMobile, isIos } = useDeviceDetection();
    useVirtualKeyboardDetector(
        () => {
          console.log("Virtual Keyboard - OPENED")
        },
        () => {
          console.log("Virtual Keyboard - CLOSED");
          if (activeElement.value && !isIos) {
            activeElement.value.blur();
          }
        }
    );
    return {
      activeElement,
      isMobile,
      isVisible,
    }
  }
};
*/
</script>