<template>
  <path
    fill="currentColor"
    d="M8,1.5c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5S1.5,11.6,1.5,8S4.4,1.5,8,1.5z M4.2,2.9l5.1-1.2l5.1,1.2v5.7
	c0,1.9-1.1,3.6-2.8,4.5l-2.3,1.1L7,13c-1.7-0.8-2.8-2.5-2.8-4.4V2.9z"
  />
  <g>
    <path
      :fill="color"
      d="M7.4,7.4C7,7.4,6.6,7.2,6.3,6.9C6,6.7,5.8,6.2,5.8,5.8C5.8,5.4,6,5,6.3,4.7C6.6,4.4,7,4.3,7.4,4.3
      s0.8,0.2,1.1,0.4C8.8,5,8.9,5.4,8.9,5.8c0,0.4-0.2,0.8-0.4,1.1S7.8,7.4,7.4,7.4z"
    />
    <path
      :fill="color"
      d="M10.1,10.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2H5.4c-0.2,0-0.4-0.1-0.6-0.2
      c-0.2-0.2-0.2-0.3-0.2-0.6v-0.4c0-0.5,0.2-1,0.6-1.4C5.5,8.4,6,8.2,6.6,8.2h1.6c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4
      L10.1,10.5L10.1,10.5z M10.5,7.3L11.8,6c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.2,0.1,0.2s0,0.2-0.1,0.3l-1.6,1.6
      c-0.1,0.1-0.2,0.1-0.3,0.1s-0.2,0-0.3-0.1L9.5,7.3c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.2c0,0,0-0.1,0.1-0.1
      c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.2,0c0,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0.1L10.5,7.3z"
    />
  </g>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>
