<template>
  <AlertBox
    v-if="isVisible"
    class="text-base bg-primary-9 text-neutral-1"
  >
    <p
      class="mb-2"
      v-html="$t('base.cookieDataPrivacyAlert', {
        usagePolicies: $t('base.usagePolicies'),
        usagePoliciesLink: $t('urls.usagePolicies'),
        dataSecurityPolicies: $t('base.dataSecurityPolicies'),
        dataSecurityPoliciesLink: $t('urls.dataSecurityPolicies'),
        cookiesPolicy: $t('base.cookiesPolicy'),
        cookiesPoliciesLink: $t('urls.cookiesPolicy'),
      })"
    />
    <div class="md:mt-0">
      <span>
        {{ $t('base.language') }}:
      </span>
      <span>
        <LanguageIconSelector
          class="inline ml-1 mr-1 -mt-0.5"
          :language="useMultiLang.activeLanguage.value"
        />
      </span>
      <span class="mr-2">
        {{ $t('base.selectedLanguage', {
          language: useMultiLang.activeLanguage.value.label
        }) }}
      </span>
      <br class="md:hidden">
      <div class="mt-1.5 block md:inline">
        <a
          class="font-bold cursor-pointer"
          @click.prevent="showLanguageModal = true"
        >
          {{ $t('base.change') }}
        </a>
        |
        <a
          class="font-bold cursor-pointer"
          @click.prevent="closeAlert"
        >
          {{ $t('base.submit') }}
        </a>
      </div>
    </div>
  </AlertBox>


  <teleport to="body">
    <LanguageModal
      v-if="showLanguageModal"
      @close="showLanguageModal = false"
      @submit="handleSubmitLanguageModal"
    />
  </teleport>
</template>
<script>
import { ref, computed } from "vue";
import Cookies from "js-cookie";
import useMultiLanguages from "../../composables/useMultiLanguages";
import useTheme from "../../composables/LayoutAndStyle/useTheme";
import AlertBox from "../Common/AlertBox";
import LanguageModal from "../Common/Language/LanguageModal";
import LanguageIconSelector from "../Common/Language/LanguageIconSelector";


export default {
  name: "CookieDataPrivacyAlert",
  components: {
    LanguageIconSelector,
    AlertBox,
    LanguageModal
  },
  setup() {
    const { theme } = useTheme();
    const useMultiLang = useMultiLanguages();
    const showLanguageModal = ref(false);
    const privacyModalClosedCookie = ref(Cookies.get('privacy_modal_closed'));
    const isVisible = computed(() => !privacyModalClosedCookie.value && theme.value.showPrivacyBar);

    function handleSubmitLanguageModal() {
      showLanguageModal.value = false;
      closeAlert();
    }

    const closeAlert = () => {
      Cookies.set('privacy_modal_closed', true, { expires: 365 });
      privacyModalClosedCookie.value = true;
    }

    return {
      handleSubmitLanguageModal,
      isVisible,
      showLanguageModal,
      closeAlert,
      useMultiLang,
    }
  }
}
</script>
