<template>
  <path
    :fill="color"
    d="M41,7.1L217,175c9.4,9.4,9.4,24.6,0,34L41,376.9c-15.1,15.1-41,4.4-41-17V24.1C0,2.7,25.8-8,41,7.1z"
  />
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    }
  }
}
</script>
