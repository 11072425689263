<template>
  <path
    class="st0"
    d="M18.8,16.6c0.6,0.6,0.6,1.6,0,2.2c-0.6,0.6-1.6,0.6-2.2,0l-4.5-4.5l-4.5,4.5c-0.6,0.6-1.6,0.6-2.2,0
	c-0.6-0.6-0.6-1.6,0-2.2l4.5-4.5L5.2,7.5c-0.6-0.6-0.6-1.6,0-2.2s1.6-0.6,2.2,0l4.5,4.5l4.5-4.5c0.6-0.6,1.6-0.6,2.2,0
	s0.6,1.6,0,2.2L14.3,12L18.8,16.6L18.8,16.6z"
  />
</template>

<script>
export default {
  name: "CloseIcon",
};
</script>
