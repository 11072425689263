import apiClient from "../api";

export default {
  /**
   * Used to verify in basic level
   * Used to complete MFA in advanced level (Part 2 of 2)
   * Get the auth token
   * @param requestId
   * @param recipientId
   * @param auth = {
   *   hash,
   *   phonenumber (security level: advanced),
   *   auth: { access_pin } (security level: advanced),
   * }
   * @return {Promise<AxiosResponse<T>>}
   */
  authenticateToken(requestId, recipientId, auth) {
    return apiClient.post(
      `/requests/${requestId}/${recipientId}/auth/token`,
      JSON.stringify(auth)
    );
  },

  /**
   * MFA Part 1 of 2.
   * Use this for ADVANCED security level
   * @param requestId
   * @param recipientId
   * @param auth
   * @return {Promise<AxiosResponse<T>>}
   */
  startMFA(requestId, recipientId, auth) {
    return apiClient.post(
      `/requests/${requestId}/${recipientId}/auth/mfa`,
      JSON.stringify(auth)
    );
  },
};
