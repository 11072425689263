<template>
  <modal
    :full-screen="isLandscape"
    @onCancel="$emit('onCancel')"
  >
    <template
      v-if="!isLandscape"
      #title
    >
      {{ $t("signature.addSignature") }}
    </template>

    <template #description>
      <div class="flex items-center justify-between">
        <SignatureTabs
          :active-tab="activeTab"
          :class="isLandscape ? 'justify-start' : 'justify-arround md:justify-start'"
          @setTab="tab => setTab(tab)"
        />
        <div
          v-if="isLandscape"
          class="flex items-center -mt-4 space-x-2"
        >
          <BaseButton
            small
            outlined
            @click="$emit('onCancel')"
          >
            {{ $t("base.cancel") }}
          </BaseButton>
          <BaseButton
            success
            small
            :loading="isPending"
            :disabled="!formValid"
            @click.once="confirmSignature"
          >
            {{ $t("base.submit") }}
          </BaseButton>
        </div>
      </div>

      <InputDrawPad
        v-if="activeTab === 'draw'"
        ref="inputDraw"
        :default-signature="defaultSignature"
        @onValidationChanged="bool => setFormValid(bool)"
      />

      <InputType
        v-else-if="activeTab === 'type'"
        ref="inputType"
        :default-signature="defaultSignature"
        @onValidationChanged="bool => setFormValid(bool)"
      />

      <InputUpload
        v-else-if="activeTab === 'upload'"
        ref="inputUpload"
        :default-signature="defaultSignature"
        @onValidationChanged="bool => setFormValid(bool)"
      />

      <InputMobile
        v-else-if="activeTab === 'mobile'"
        ref="inputMobile"
        :default-signature="defaultSignature"
        @onValidationChanged="bool => setFormValid(bool)"
      />
    </template>

    <template
      v-if="! isLandscape"
      #buttons
    >
      <BaseButton
        small
        outlined
        @click="$emit('onCancel')"
      >
        {{ $t("base.cancel") }}
      </BaseButton>
      <BaseButton
        success
        small
        :loading="isPending"
        :disabled="!formValid"
        @click.once="confirmSignature"
      >
        {{ $t("base.submit") }}
      </BaseButton>
    </template>
  </modal>
</template>

<script>
import { ref, computed, provide, onMounted } from "vue";
import { useMediaQuery } from "@vueuse/core";
import {useStore} from "vuex";
import useSignatureFonts from "../../../composables/LayoutAndStyle/useSignatureFonts";
import SignatureTabs from "../Signature/SignatureTabs";
import Modal from "../../Common/Modal";
import InputType from "../Signature/InputType";
import InputMobile from "../Signature/InputMobile";
import InputDrawPad from "../Signature/InputDrawPad";
import WebFont from "webfontloader";
import InputUpload from "../Signature/InputUpload";

export default {
  name: "SignatureModal",
  components: {
    InputUpload,
    InputDrawPad,
    InputMobile,
    InputType,
    Modal,
    SignatureTabs,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  emits: ["onSubmit", "onCancel"],
  setup(props, { emit }) {
    const store = useStore();
    const isPending = ref(false);
    const formValid = ref(false);
    const activeTab = ref("draw");
    const inputType = ref(null);
    const inputDraw = ref(null);
    const inputMobile = ref(null);
    const inputUpload = ref(null);
    const isLandscape = useMediaQuery('screen and (max-width:800px) and (orientation:landscape)')

    const signatureTemplate = computed(() => {
      return store.state.documentEditor.signatureTemplate;
    });

    const defaultSignature = computed(() => {
      return props.field.signature ? props.field.signature : signatureTemplate.value;
    });

    /* set this dynamic with useDeviceDetection if device dependent logic control in child components is needed */
    /* default false because Dropzone is expecting a value through injection (also provided on MobileSignApp view) */
    provide("mobileMode", false);

    const { rawFonts } = useSignatureFonts();
    WebFont.load({ google: { families: rawFonts.map(el => el.font) } });

    onMounted(() => {
      if (props.field && props.field.signature && props.field.signature.type) {
        activeTab.value = props.field.signature.type;
      } else if (signatureTemplate.value) {
        activeTab.value = signatureTemplate.value.type;
      }
    });

    function setTab(tab) {
      activeTab.value = tab;
      /* reset valid state of form */
      setFormValid(false);
    }

    function confirmSignature() {
      function emitObj(signatureObj) {
        console.log("CACHE SIGNATURE", signatureObj);
        store.commit('documentEditor/setSignatureTemplate', signatureObj);

        emit("onSubmit", signatureObj);
      }

      switch (activeTab.value) {
        case "type":
          inputType.value.getSignatureObj(emitObj);
          break;
        case "draw":
          inputDraw.value.getSignatureObj(emitObj);
          break;
        case "mobile":
          inputMobile.value.getSignatureObj(emitObj);
          break;
        case "upload":
          inputUpload.value.getSignatureObj(emitObj);
          break;
      }
      //
      // emit('signed', {
      //   type: activeTab.value,
      //   typefont: null,
      //   text: null,
      //   blob: getBlobFromBlobString(this.pendingSignature.blob),
      //   image: createSignatureImageFromBlob(this.pendingSignature.blob),
      // });
    }

    function setFormValid(bool) {
      setTimeout(() => {
        formValid.value = bool;
      }, 200)
    }


    return {
      activeTab,
      confirmSignature,
      inputType,
      inputDraw,
      inputUpload,
      inputMobile,
      formValid,
      setTab,
      isPending,
      setFormValid,
      defaultSignature,
      isLandscape,
    };
  },
};
</script>

<style scoped></style>
