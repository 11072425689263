<template>
  <li class="text-center">
    <button
      type="button"
      :onClick="onClick"
      :class="[isActive ? 'text-primary-3' : 'text-neutral-7']"
      class="inline-block px-3.5 py-2 font-medium focus:outline-none whitespace-nowrap"
    >
      <slot />
    </button>
    <div
      v-if="isActive"
      class="m-auto -mt-1 border-b-2 border-primary-3 w-16 md:w-11/12"
    />
  </li>
</template>

<script>
export default {
  name: "SignatureTab",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
};
</script>
