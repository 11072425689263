<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="flex-shrink-0"
    :width="size"
    :height="size"
    :viewBox="viewBox"
    :aria-labelledby="name"
  >
    <title :id="name">{{ name }}</title>
    <g :fill="color">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "24",
    },
    viewBox: {
      type: String,
      default: "0 0 24 24",
      required: false
    },
    color: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style></style>
