<template>
  <transition
    enter-active-class="transition-opacity ease-linear"
    leave-active-class="transition-opacity ease-linear"
    enter-from-class="opacity-0"
    leave-to-class="opacity-0"
    appear
  >
    <slot />
  </transition>
</template>